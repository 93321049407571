import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Query } from 'react-apollo';
import { Loading } from '../components';
import { ApolloConsumer } from 'react-apollo';
import ArrowTooltip from './arrow-tooltips';
import gql from 'graphql-tag';
import Fab from './fab';
const useStyles = makeStyles({
  heading: {
    width: '17rem',
    '&:hover': {
      'text-shadow': '0px 1px #55b8de'
    }
  },
  formControl: {
    margin: '1rem',
    display: 'flex',
    wrap: 'nowrap'
  },
  htmlTooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: '20rem',
    fontSize: '1rem',
    border: '1px solid #dadde9',
  },
  formContainer: {
    margin: '0 auto'
  },
  menuItem: {
    width: 'auto'
  },

});

export const GET_TABLE = gql`
  query GetTable {
    table @client
    disabled @client
  }

`;
export default function Tables() {
  const classes = useStyles();
  const theRef = useRef()

  return (
    <ApolloConsumer>
    {client => (
      <Query query={GET_TABLE}>
        {({ data, loading, error }) => {
          if (loading) return <p><Loading/></p>;
          if (error) return <p>ERROR: {error.message}</p>;
          //console.log('Tables data', data)
          return data && 
          <div>
            <ArrowTooltip title="Select region within Greater Sydney" 
            placement="right"
            data-cy="filter-suburb-tooltip"
            >
              
              <Typography variant="h6" gutterBottom className={classes.heading}>
              <Fab label="Step 1" text="1" />
              Select region:
              </Typography>
            </ArrowTooltip>
            <div className={classes.formContainer}>
            <FormControl variant="outlined" className={classes.formControl} disabled={data.disabled}>
              <InputLabel ref={theRef} htmlFor="outlined-tables">Select region:</InputLabel>
              <Select autoWidth={true}
                value={data.table}
                onChange={(e, edata) => {
                  //console.log('onchange e', e, 'edata', edata)
                  client.writeData({ data: { 
                    table: e.target.value, 
                    selected_ssc_code16: null,
                    lat: edata.props.lat,
                    lon: edata.props.lon,
                    fullDetail: false
                   } });
                }}
                input={
                  <OutlinedInput
                    labelWidth={150}
                    name="tables"
                    id="outlined-tables"
                  />
                }
              >
                <MenuItem className={classes.menuItem} value="scores_all_suburbs" lat="-33.6994639524574" lon="150.953607835751">All Suburbs</MenuItem>
                <MenuItem className={classes.menuItem} value="scores_western_city" lat="-33.9911749759871" lon="150.680851223301">Western City</MenuItem>
                <MenuItem className={classes.menuItem} value="scores_central_city" lat="-33.6575917641627" lon="150.947557881573">Central City</MenuItem>
                <MenuItem className={classes.menuItem} value="scores_eastern_city" lat="-33.9401588737116" lon="151.181979929093">Eastern City</MenuItem>
                <MenuItem className={classes.menuItem} value="scores_north_district" lat="-33.7222053867732" lon="151.211347444308">North District</MenuItem>
                <MenuItem className={classes.menuItem} value="scores_south_district" lat="-33.9825004121422" lon="151.074510898451">South District</MenuItem>
              </Select>
            </FormControl>
            </div>
          </div>

        }}
      </Query>
    )}
    </ApolloConsumer>
  );

}
