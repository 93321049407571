import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Control from 'react-leaflet-control';
import Typography from '@material-ui/core/Typography';
import {mapColor} from './config';
import ArrowTooltip from '../arrow-tooltips';
const useStyles = makeStyles({
  controlContainer: {
    background: '#000',
    width: '30rem',
    height: '8rem',
    'box-shadow': '0 2rem 5rem rgba(0, 0, 0, 0.9)',
    'border-radius': '5px',
    'text-align': 'center',
    'color': '#fff',
    
  },
  'color-ramp': {
    display: 'grid',
    'grid-template-columns': '5rem 1fr 5rem',
    height: '3rem',
  },
  'color-ramp__colors': {
    'background': `linear-gradient(90deg, ${mapColor[0]} 0%, ${mapColor[1]} 15%, ${mapColor[2]} 30%, ${mapColor[3]} 45%,  ${mapColor[4]} 60%,  ${mapColor[5]} 75%,  ${mapColor[6]} 90%)`
  },
  'color-ramp__text': {
    'align-self': 'center'
  },

});

export default function Legend() {
  const classes = useStyles();
  return (
    <Control position="bottomleft" className={classes.controlContainer}>
        <Typography variant="h6" gutterBottom>
          Suburb suitability
        </Typography>
        <div className={classes['color-ramp']}>
          <span  className={classes['color-ramp__text']}>Low</span>
          <ArrowTooltip 
            title="(Based on your preferences), low suitability areas are shown in red with very high suitability in blue." 
            placement="top"
            data-cy="map-legend-tooltip">
            <span className={classes['color-ramp__colors']}></span>
          </ArrowTooltip>
          <span  className={classes['color-ramp__text']}>High</span>
        </div>
    </Control>
  );
}
