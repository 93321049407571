import carto from "@carto/carto.js";

export default new carto.Client({
  serverUrl: "https://louse.geodb.host/user/admin4udia",
  apiKey: "8cnAePXj8SzRJ3uRXI7TfA",
  //serverUrl: "https://moose.geod8cnAePXj8SzRJ3uRXI7TfAb.host:443/user/admin4udia",
  //apiKey: "2bcf2dc4df5288763d129f0d3aba88f854de4973",
  username: "admin4udia",
});

/* The attribution to be shown at the bottom of the map */
export const attribution =
  '<a href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a> | © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, © <a href="https://carto.com/attribution">CARTO</a>';

export const tileWithLabel =
  "https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_only_labels/{z}/{x}/{y}{r}.png"; // use this one

export const tileNoLabel = {
  dark:
    "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_nolabels/{z}/{x}/{y}.png",
  terrain:
    "https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png",
  light:
    "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_nolabels/{z}/{x}/{y}.png",
};
export const mapColor = [
  "#d53e4f",
  "#fc8d59",
  "#fee08b",
  "#ffffbf",
  "#e6f598",
  "#99d594",
  "#3288bd",
];
export const ranking = [
  "Undesirable",
  "Very poor",
  "Poor",
  "Average",
  "Good",
  "Very good",
  "Excellent",
];

export const setMapOpacity = (opacity, totalRows) => {
  const quantiles = totalRows < 7 ? totalRows : 7;
  //console.log('quantiles', quantiles, 'totalRows', totalRows)
  return `#greater_sydney_suburb {
        polygon-fill: ramp([render_score], (${mapColor.join(
          ","
        )}), quantiles(${quantiles}));
        polygon-opacity: ${opacity / 100};
        line-width: 1;
        line-color: #a0dffa;
        line-opacity: ${opacity / 100};
    }`;
};
export const mapConfig = {
  mapCenter: [-33.5473567, 150.6517955],
  zoomLevel: 9,
  minZoom: 9,
  tileWithLabel:
    "https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_only_labels/{z}/{x}/{y}{r}.png",
  tileNoLabel: {
    dark:
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_nolabels/{z}/{x}/{y}.png",
    terrain:
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png",
    light:
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_nolabels/{z}/{x}/{y}.png",
  },
  attribution:
    '<a href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a> | © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, © <a href="https://carto.com/attribution">CARTO</a>',
  layers: {
    sydney: {
      sql: `SELECT * FROM admin4udia.greater_sydney`,
      cartocss: `#greater_sydney {
                polygon-fill: #ff3959;
                polygon-opacity: 0;
                line-width: 2;
                line-color: #fff;
                line-opacity: 0.74;
                } `,
      options: {
        featureClickColumns: [],
        featureOverColumns: [],
      },
    },
    sydneySuburb: {
      options: {
        featureClickColumns: [
          "ssc_code16",
          "ssc_name16",
          "score_transport",
          "score_amenity",
          "score_health",
          "score_education",
          "score_employment",
          "score_environment",
          "score_community",
          "lat",
          "lon",
          "buy_house",
          "buy_unit",
          "rent_house",
          "rent_unit",
        ],
        featureOverColumns: [
          "ssc_code16",
          "ssc_name16",
          "score_transport",
          "score_amenity",
          "score_health",
          "score_education",
          "score_employment",
          "score_environment",
          "score_community",
          "lat",
          "lon",
          "buy_house",
          "buy_unit",
          "rent_house",
          "rent_unit",
        ],
      },
    },

    sydneySuburbEmpty: {
      cartocss: `#greater_sydney_empty {
                polygon-opacity: 0;

                line-opacity: 0;
                } `,
      options: {
        featureClickColumns: [
          "ssc_code16",
          "ssc_name16",
          "score_transport",
          "score_amenity",
          "score_health",
          "score_education",
          "score_employment",
          "score_environment",
          "score_community",
          "lat",
          "lon",
          "buy_house",
          "buy_unit",
          "rent_house",
          "rent_unit",
        ],
        featureOverColumns: [
          "ssc_code16",
          "ssc_name16",
          "score_transport",
          "score_amenity",
          "score_health",
          "score_education",
          "score_employment",
          "score_environment",
          "score_community",
          "lat",
          "lon",
          "buy_house",
          "buy_unit",
          "rent_house",
          "rent_unit",
        ],
      },
    },
    lga: {
      sql: `SELECT cartodb_id, the_geom, the_geom_webmercator, lga_code16, lga_name16, areasqkm16 from greater_sydney_lga`,
      cartocss: `#lga {
                ::shadow {
                    polygon-fill: #374c70;
                    polygon-opacity: 0;
                    line-width: 5;
                    line-color: #000000;
                    line-opacity: 0.3;
                    polygon-comp-op: darken;
                        image-filters: agg-stack-blur(5,5);
                    }
                ::line {
                    polygon-fill: #374c70;
                    polygon-opacity: 0;
                    line-width: 2;
                    line-color: white;
                    line-opacity: 1;
                    polygon-comp-op: darken;
                    }
                }
                [zoom >= 12]#lga::labels { 
                    text-name: [lga_name16];
                    text-face-name: 'DejaVu Sans Book';
                    text-size: 10;
                    text-fill: #FFFFFF;
                    text-label-position-tolerance: 0;
                    text-halo-radius: 1;
                    text-halo-fill: #6F808D;
                    text-dy: -10;
                    text-allow-overlap: true;
                    text-placement: point;
                    text-placement-type: dummy;
                  }     
               `,
      options: {
        featureClickColumns: [],
        featureOverColumns: [],
      },
    },
  },
};

export const boundArray = [
  [-27.6603865819999, 152.679693133],
  [-27.021964587, 153.468488646],
];
