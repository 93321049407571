import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Dot from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles({
  education: {
    'margin-top': '1rem',
    'text-align': 'justify'
  },
  dot: {
    'font-size': '1rem',
    'color': '#fff',
    'margin': '0 1rem',

  }
});

export default function Education(props) {
  const classes = useStyles();

  return (
    <div className={classes.education}>
        <Typography variant="h5">
        Education methodology
        </Typography>
        <Typography variant="body1" paragraph>
          The Education score is based on each suburb’s accessibility to educational facilities including:
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} />Government primary schools
        </Typography>
        <Typography variant="body1" paragraph>
           <Dot className={classes.dot} />Government secondary schools
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} />Pre-school / child day-care centres
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} />Private primary schools
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> Private secondary schools
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} />Special development schools
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> TAFEs
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} />Universities
        </Typography>
        <Typography variant="h6">
          Data source:
        </Typography>
        <Typography variant="body1" paragraph>
        <Dot className={classes.dot} /> Catchment and primary/secondary schools (private and public), special schools:
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> <Link href="https://data.cese.nsw.gov.au/data/dataset/027493b2-33ad-3f5b-8ed9-37cdca2b8650" target="_blank" rel="noreferrer"> Master dataset: NSW government school locations and student enrolment numbers</Link>
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> <Link href="https://data.cese.nsw.gov.au/data/dataset/1d019767-d953-426c-8151-1a6503d5a08a" target="_blank" rel="noreferrer">NSW non-government school locations (2017)</Link>
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /><Link href="https://data.cese.nsw.gov.au/data/dataset/ffd4298f-c4b2-48de-a939-e8ba8cc1b70a" target="_blank" rel="noreferrer"> School intake zones (catchment areas) for NSW government schools</Link>
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> TAFE and University:  <Link href="http://maps.data.nsw.gov.au/" target="_blank" rel="noreferrer">NSW live</Link>
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> <Link href="https://www.childcarefinder.gov.au/" target="_blank" rel="noreferrer">Preschools:</Link> subtract data every 6km radius
        </Typography>

        <Typography variant="h6">
            Method:
        </Typography>
        <Typography variant="subtitle1">
          <strong>Public primary and secondary schools</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          1. This follows the school catchment zones. That is, if the centroid of the SA1 falls within a school catchment zone, all SA1 residents are assigned to that specific school.
        </Typography>

        <Typography variant="body1" paragraph>
          2. We then record the driving time from each SA1 centroid to school, aggregate for each SA2 and then rank
        </Typography>
        <Typography variant="subtitle1">
          <strong>All other categories:</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          These are only based on proximity (using estimated driving time). The thresholds for the categories are:
        </Typography>
        <Typography variant="body1" paragraph>
        <Dot className={classes.dot} /> Pre-schools: 15 min
        </Typography>
        <Typography variant="body1" paragraph>
        <Dot className={classes.dot} /> Private primary schools: 30 min
        </Typography>
        <Typography variant="body1" paragraph>
        <Dot className={classes.dot} /> Private secondary schools: 30 min
        </Typography>
        <Typography variant="body1" paragraph>
        <Dot className={classes.dot} /> TAFEs: 45 min
        </Typography>
        <Typography variant="body1" paragraph>
        <Dot className={classes.dot} /> Universities: 45 min
        </Typography>
        <Typography variant="body1" paragraph>
        <Dot className={classes.dot} /> Special Schools: 45 min
        </Typography>
        <Typography variant="body1" paragraph>
          For each category, the number of facilities accessible to a specific suburb within the relevant threshold is calculated. A log-minmax transformation is then applied to scale the score and adjust for skewness. As there are only 46 TAFE, and 40 university, campuses, those two categories default weight is set to 0.5. All other categories weights are set to 1. The total score is summed and then rescale to between zero and 10.
        </Typography>
        

    </div>
  );
}
