import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    about: {
        'text-align': 'justify'
    }
});

export default function AboutTool(props) {
  const classes = useStyles();

  return (
    <div className={classes.about}>
        <Typography variant={props.heading}>
            About the Tool
        </Typography>
        <Typography variant="body1" paragraph>
            City Life Analytics visualises the most liveable parts of Greater Sydney based on the user’s personal preferences. Unlike most liveability tools, City Life Analytics incorporates environmental variables and property price information, overlaid with the more typical infrastructure and services data.
        </Typography>
        <Typography variant="body1" paragraph>
            To find your most preferred, liveable suburb, you assign weightings to the different location-based environmental, facility and service features according to your own preferences. Using the sliders in the toolbar, you can adjust these weighting to your optimal mix. The ability to control the trade-off between features creates your own unique and personalised version of liveability.
        </Typography>
    </div>
  );
}
