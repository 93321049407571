import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles({
  fab: {
    margin: '0.5rem',
    'font-size': '2rem',
    width: '3.5rem',
    height: '3.5rem'
  },
});

export default function Footer(props) {
  const classes = useStyles();

  return (
    <Fab color="primary" aria-label={props.label} className={classes.fab}>{props.text}</Fab>
  );
}
