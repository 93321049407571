import React, { Fragment } from 'react';
import { Router } from '@reach/router';

import Home from './home';

import  PageContainer from '../components/page-container';

export default function Pages() {
  return (
    <Fragment>
      <PageContainer>
        <Router primary={false} component={Fragment}>
          <Home path="/" />
        </Router>
      </PageContainer>
    </Fragment>
  );
}
