import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContainer from './tabContainer';
import ScatterChart from './scatter-chart';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function ScatterChartTabs({data, column, selected_ssc_code16}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
  
    function handleChange(event, newValue) {
      setValue(newValue);
    }
  
    return (
      <div className={classes.root}>
        <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        >
        <Tab label="Density, Population and Price" data-cy="tab-density" />
        <Tab label="Liveability Features and Price" data-cy="tab-score"/>
        </Tabs>
            {value === 0 && 
            <TabContainer>
                <ScatterChart data={data}
                column={column}
                type="density" 
                selected_ssc_code16={selected_ssc_code16} />
            </TabContainer>}
          {value === 1 && 
            <TabContainer>
                <ScatterChart data={data}
                column={column}
                type="score" 
                selected_ssc_code16={selected_ssc_code16} />
            </TabContainer>}
      </div>
    );
}
