import React from 'react';
import { makeStyles } from '@material-ui/styles';
import udia from '../assets/images/udia.svg';
import geografia from '../assets/images/geografia.png';

const useStyles = makeStyles({
  footer: {
    'position': 'absolute',
    'bottom': '0.5rem',
    'left': '50%',
    transform: 'translate(-50%, 0)',
    'width': '26rem',
    'text-align': 'center'
  },

  icon: {
    'fontSize': '3rem',
  },

  logo__geografia: {
    width: '14rem',
    'margin-left': '2rem'
  }, 
  logo__udia: {
    width: '3rem'
  }
});

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <a target="blank" href="http://www.udiansw.com.au/" className={classes.icon}>
        <img className={`${classes.logo} ${classes.logo__udia}`} src={udia} alt="udia logo" />
      </a>
      <a target="blank" href="https://geografia.com.au/" className={classes.icon}>
        <img className={`${classes.logo} ${classes.logo__geografia}`} src={geografia} alt="geografia logo" />
      </a>
    </div>
  );
}
