import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import StepSlider from './slider';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import {getPointsBalance} from '../utils/utils';
import Typography from '@material-ui/core/Typography';
import ArrowTooltip from './arrow-tooltips';
import {colors} from '../styles';
import Fab from '../components/fab';
import numeral from 'numeral';

const useStyles = makeStyles({
  root: {
    width: '100%',
    'margin-bottom': '1rem'
  },
  heading: {
    'width': '25rem'
  },
  button: {
    'font-size': '2rem',
    'padding': '0 1rem'
  },
  primary: {
    color: colors.secondary
  },
  secondary: {
    color: colors.red
  },
  resetContainer: {
    display: 'flex',
    'justify-content': 'space-between',
    'align-items': 'center',
    'margin-right': '0.5rem'
  }
});

export const GET_STATUS = gql`
  query GetStatus {
    disabled @client
  }
`;

export default function SliderPool() {
  const classes = useStyles()
  const [transport, setTransport] = useState(14)
  const [amenity, setAmenity] = useState(14)
  const [health, setHealth] = useState(14)
  const [education, setEducation] = useState(14)
  const [employment, setEmployment] = useState(14)
  const [environment, setEnvironment] = useState(15)
  const [community, setCommunity] = useState(15)

  let pointsBalance = getPointsBalance(transport, amenity, health, education, employment, environment, community)
  let buttonTootip, remainingPointsClassName

  if (pointsBalance.disabled)  {
    buttonTootip = "Make sure there are no points left to allocate before submitting"
    remainingPointsClassName = "secondary"
  } 
  else {
    buttonTootip = "Calculate suburb liveability based on your preferences"
    remainingPointsClassName = "primary"
  }  
  //console.log('pointsBalance', pointsBalance, 'transport', transport)

  return (
      <Query query={GET_STATUS}>
        {({ data, client }) => {
          return (
            <div className={classes.root}>  
              <div className={classes.heading}>
                <ArrowTooltip title="Using the sliders below, allocate 100 points to indicate what is most important to you" 
                placement="right"
                data-cy="slider-header-tooltip"
                >              
                  <Typography variant="h6">
                  <Fab label="Step 2" text="2" /> Liveability features:
                  </Typography>

                </ArrowTooltip>
              </div>
              <StepSlider label="Transport" labelId="transport" value={transport} icon="train" index={0}
              tooltips="How important is walkability and access to public transport?"
                onPropChange={(value, labelId) => {
                  pointsBalance = getPointsBalance(value, amenity, health, education, employment, environment, community)
                  setTransport(value)
                  client.writeData({ data: {disabled: pointsBalance.disabled} });  
                
                }} />
              <StepSlider label="Amenity" labelId="amenity" value={amenity} icon="store" index={1}
              tooltips="How important is cultural diversity and public safety and proximity to amenities like retail, civic, community and emergency services?"
                onPropChange={(value, labelId) => {
                  pointsBalance = getPointsBalance(transport, value, health, education, employment, environment, community)
                  setAmenity(value)
                  client.writeData({ data: {disabled: pointsBalance.disabled} }); 
              }} />
              <StepSlider label="Health" labelId="health" value={health} icon="local_hospital" index={2}
              tooltips="How important is proximity to health facilities (hospitals, medical centres and active exercise options)?"
                onPropChange={(value, labelId) => {
                  pointsBalance = getPointsBalance(transport, amenity, value, education, employment, environment, community)

                  setHealth(value)
                  client.writeData({ data: {disabled: pointsBalance.disabled} }); 
              }} />
              <StepSlider label="Education" labelId="education" value={education} icon="school" index={3}
              tooltips="How important is proximity to education facilities of all levels, including libraries?"
                onPropChange={(value, labelId) => {
                  pointsBalance = getPointsBalance(transport, amenity, health, value, employment, environment, community)
                  setEducation(value)
                  client.writeData({ data: {disabled: pointsBalance.disabled} }); 
              }} />
              <StepSlider label="Employment" labelId="employment" value={employment} icon="work" index={4}
              tooltips="How important is proximity to large employment precincts with a mix of jobs?"
                onPropChange={(value, labelId) => {
                  pointsBalance = getPointsBalance(transport, amenity, health, education, value, environment, community)
                  setEmployment(value)
                  client.writeData({ data: {disabled: pointsBalance.disabled} }); 
              }} />
              <StepSlider label="Environment" labelId="environment" value={environment} icon="terrain" index={5}
              tooltips="How important is local environmental quality (low light, noise and heat pollution)?"
                onPropChange={(value, labelId) => {
                  pointsBalance = getPointsBalance(transport, amenity, health, education, employment, value, community)
                  setEnvironment(value)
                  client.writeData({ data: {disabled: pointsBalance.disabled} }); 
              }} />
              <StepSlider label="Community" labelId="community" value={community} icon="group" index={6}
              tooltips="How important is community?"
                onPropChange={(value, labelId) => {
                  pointsBalance = getPointsBalance(transport, amenity, health, education, employment, environment, value)
                  setCommunity(value)
                  client.writeData({ data: {disabled: pointsBalance.disabled} }); 
              }} />
              <div className={classes.resetContainer}>
                <Typography variant="h6">
                    Remaining points:&nbsp;
                    <span className={classes[remainingPointsClassName]} data-cy="remaining-points">
                      {numeral(pointsBalance.remainingPoints).format('0')}
                    </span>
                </Typography>
                <ArrowTooltip 
                  title={buttonTootip}
                  placement="right"
                  data-cy="submit-tooltip">

                  <Button variant="contained"  color="primary" 
                  disabled={data.disabled}
                  size="large"
                  className={classes.button}
                  data-cy="button-submit"
                  onClick={(e) => {
                    e.preventDefault()
                    //console.log('click')
                    let updatedData = {}
                    updatedData.transport = transport
                    updatedData.amenity = amenity
                    updatedData.health = health
                    updatedData.education = education
                    updatedData.employment = employment
                    updatedData.environment = environment
                    updatedData.community = community
                    updatedData.selected_ssc_code16 = null
                    updatedData.lat = null
                    updatedData.lon = null
                    updatedData.fullDetail= false 

                    client.writeData({ data: updatedData });
                    
                  }}>Submit</Button>
                  
                </ArrowTooltip>
                <ArrowTooltip 
                  title="Reset points to the default values setting"
                  placement="right"
                  data-cy="reset-tooltip"
                  >
                    <Button color="secondary" 
                    data-cy="button-reset"
                    size="large"
                    className={classes.button}
                    onClick={(e) => {
                      e.preventDefault()
                      setTransport(14)
                      setAmenity(14)
                      setHealth(14)
                      setEducation(14)
                      setEmployment(14)
                      setEnvironment(15)
                      setCommunity(15)
                      client.writeData({ data: {disabled: false} }); 
                    }}>Reset</Button>
                  
                </ArrowTooltip>
              </div>
            </div>
          )}
        }
      </Query>

  ); 

}

