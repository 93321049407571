import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Dot from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles({
  employment: {
    'margin-top': '1rem',
    'text-align': 'justify'
  },
  dot: {
    'font-size': '1rem',
    'color': '#fff',
    'margin': '0 1rem',

  }
});

export default function Employment(props) {
  const classes = useStyles();

  return (
    <div className={classes.employment}>
        <Typography variant="h5">
        Employment methodology
        </Typography> 
        <Typography variant="body1" paragraph>
          Accessibility to jobs and to precincts with a higher than average mix of jobs (defined by occupation).
        </Typography>
        <Typography variant="h6">
          Data source:
        </Typography>
        <Typography variant="body1" paragraph>
          Australian Bureau of Statistics, 2016
        </Typography>
        <Typography variant="h6">
            Method:
        </Typography>
        <Typography variant="body1" paragraph>
          There are two components to the Employment model.
        </Typography>
        <Typography variant="body1" paragraph>
          1. Local job diversity is based on the number of occupations within a suburb. Here we only consider occupations with more than five people. To account for the size of the suburb, we factor in the local population count as part of the equation as follows:
          local population raw score = number of occupations + number of occupations/suburb population
        </Typography>
        <Typography variant="body1" paragraph>
          2. Accessibility to jobs: This is a journey time analysis of the diversity of jobs within a defined spatial limit. There are two subcategories to this:
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} />Accessibility by car
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} />Accessibility by public transport
        </Typography>
        <Typography variant="body1" paragraph>
          For each subcategory, we calculate the estimated travel time (driving or by public transport) from each SA2 centroid to Destination Zone centroid. Default weights for the two subcategories are:
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> Weight 1: 1,330,759
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> Weight 2: 525,719
        </Typography>
        <Typography variant="body1" paragraph>
          The two sub-indices (local job diversity and accessibility) are equally weighted, summed and then a minmax scale is applied to scale the score between zero and 10.
        </Typography>


    </div>
  );
}
