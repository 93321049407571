
import { injectGlobal } from 'react-emotion';
export const unit = 8;
export const colors = {
  primary: '#0082c5',
  secondary: '#55b8de',
  accent: '#e535ab',
  background: '#000',
  grey: '#d8d9e0',
  text: '#fff',
  textSecondary: '#fff',
  red: '#d53e4f'
};

export default () => injectGlobal({
  [['html']]: {
    'fontSize': '62.5%'
  },
  body: {
    boxSizing: 'border-box',
  },
  li: {
    'listStyle': 'none'
  },
  //override hover style, TODO: add into styled component
  '.top-suburbs a:hover': {
    'textShadow': `0 0 10px #55b8de`,
  },
  '.bottom-suburbs a:hover': {
    'textShadow': `0 0 10px #d53e4f`
  }
});
