import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
  amenity: {
    'margin-top': '1rem',
    'text-align': 'justify'
  },

});

export default function Amenity(props) {
  const classes = useStyles();

  return (
    <div className={classes.amenity}>
        <Typography variant="h5">
        Amenity methodology
        </Typography>
        <Typography variant="body1" paragraph>
          This is a straightforward accessibility ranking quantifying the number of facilities/services within a specified distance threshold. There are three amenity sub-indexes:
        </Typography>
        <Typography variant="body1" paragraph>
          1. Food and beverage: the total number of pubs, restaurants, cafes, bars, beer gardens, etc within 5km.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Shopping: the total number of shopping centres, shopping malls, marketplaces etc, within 5km.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Arts and recreation: the total number of arts centres, theatres, cinemas, galleries, museums, theme parks, zoos, aquariums, sports stadiums etc within 5km.
        </Typography>
        <Typography variant="body1" paragraph>
          The threshold for each sub index is 2,000 metres, which is approximately the 20% quantile.
        </Typography>
        <Typography variant="h6">
          Data source:
        </Typography>
        <Typography  variant="body1" paragraph>
          <Link href="https://www.openstreetmap.org" target="_blank" rel="noreferrer">Open Street Map</Link> & 
          <Link href="https://www.psma.com.au/" target="_blank" rel="noreferrer"> PSMA Australia</Link>      
        </Typography>
        <Typography variant="h6">
            Method:
        </Typography>
        <Typography variant="body1" paragraph>
          For each category, a ranking is derived and then summed with a default equal weighting.      
        </Typography>
    </div>
  );
}
