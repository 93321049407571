import React, {useState} from 'react';
import Settings from '@material-ui/icons/Settings';
import MapType from './MapType';
import MapLGA from './MapLGA';
import MapOpacity from './MapOpacity';
import { makeStyles } from '@material-ui/styles';
import Control from 'react-leaflet-control';
const useStyles = makeStyles({

  icon: {
    'color': '#000',
    'font-size': '3.5rem',
    'background': '#fff',
    'border-radius': '0.5rem'
  },

});

const Setting = () => {
  const classes = useStyles();
  const [minimise, onMinimise] = useState(true)

  const onUpdate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onMinimise(!minimise)
  }

  return (
      <Control position="bottomright">
        { minimise ? 
         <Settings className={classes.icon} onClick={onUpdate} data-cy="settings" /> 
          :
            <div data-cy="settings-container" >
              <MapLGA onUpdate={onUpdate} /> 
              <MapType />
              <MapOpacity />
            </div>
      }
      </Control>
  )
}
export default Setting;

