import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
  health: {
    'margin-top': '1rem',
    'text-align': 'justify'
  },

});

export default function Health(props) {
  const classes = useStyles();

  return (
    <div className={classes.health}>
        <Typography variant="h5">
          Health methodology
        </Typography>
        <Typography variant="body1" paragraph>
          This is an accessibility ranking quantifying the number of facilities/services within a specified distance threshold. There are four Health sub-indexes:
        </Typography>
        <Typography variant="body1" paragraph>
          1. Healthy food: the total number of supermarkets, farms, green groceries, farmers markets within 60 min walking time.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Parks: the total number of parks in the suburb.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Accessibility to exercise: the number of gyms, sports centres, swimming pools.
        </Typography>
        <Typography variant="body1" paragraph>
          4. Health care services: the number of general hospitals, medical centres, community health centres, psychiatric hospitals, clinics, dentists, pharmacies, etc within 60 min estimated driving time.
        </Typography>
        <Typography variant="h6">
          Data source:
        </Typography>
        <Typography variant="body1" paragraph>
          Health care: clinics, dentists, pharmacies – Open Street Map (OSM) and PSMA Australia.
          Other facilities: general hospitals, integrated health service, care centres, medical centres and community health centres, psychiatric hospitals - NSW live <Link href="http://maps.data.nsw.gov.au/" target="_blank" rel="noreferrer">http://maps.data.nsw.gov.au/</Link>
        </Typography>
        <Typography variant="h6">
            Method:
        </Typography>
        <Typography variant="body1" paragraph>
          ‘Parks’ and ‘exercise-related facilities’ are measured for each suburb that has access to parks or facilities within 15 minutes’ walking time. Ranking is based on the unique park or facility number. For suburbs with no parks or facilities within 15 minutes’ walking time, rank is based on the mean time to the closest five parks or facilities.
        </Typography>
        <Typography variant="body1" paragraph>
          For healthy food outlets, a 15 minute threshold is used and the mean time to the closest 15 outlets is calculated.
        </Typography>
        <Typography variant="body1" paragraph>
          For health care facilities, suburbs are divided into two subgroups:
        </Typography>
        <Typography variant="body1" paragraph>
          1. General hospitals and medical centres,
        </Typography>
        <Typography variant="body1" paragraph>
          2. All other facilities/services.
        </Typography>
        <Typography variant="body1" paragraph>
        For each group a cleaned score function is applied. Results are summed with a default equal weighting and then rescaled to between zero and 10 for each suburb.
        </Typography>


    </div>
  );
}
