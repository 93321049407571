import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0}}>
      {props.children}
    </Typography>
  );
}




