import { Component } from 'react';
import PropTypes from 'prop-types';
import carto from '@carto/carto.js';
import Popup from './Popup';
import L from "leaflet";

class Layer extends Component {

  static propTypes = {
    map: PropTypes.object,
    sql: PropTypes.string, 
    cartocss: PropTypes.string, 
    client: PropTypes.object,
    hidden: PropTypes.bool,
    visible: PropTypes.bool,
    options: PropTypes.object, 
    onClick: PropTypes.func,
    column: PropTypes.string,
    onMetadataChange: PropTypes.func,
    hasFilters: PropTypes.bool
  }

  constructor(props) {
    super(props);
    const {sql, cartocss, client } = this.props;
    const cartoSql = new carto.source.SQL(sql);
    const cartoCss = new carto.style.CartoCSS(cartocss);
    this.layer = new carto.layer.Layer(cartoSql, cartoCss);
    client.addLayer(this.layer);
    const layerStyle = this.layer.getStyle()
    layerStyle.setContent(cartocss)
  }

  componentDidMount() {

    const { client, options } = this.props;
    client.getLeafletLayer().addTo(this.props.map);

    const popup = L.popup({ closeButton: false });
    this.layer.setFeatureOverColumns(options.featureOverColumns)
    this.layer.on(carto.layer.events.FEATURE_OVER, featureEvent => {
      const dataWithColumn = {
        ...featureEvent.data, 
        column: this.props.column,
        hasFilters: this.props.hasFilters
      }
      //console.log('table', dataWithColumn, 'featureEvent', featureEvent)
      popup.setLatLng(featureEvent.latLng);
      popup.setContent(Popup(dataWithColumn));
      popup.openOn(this.props.map);
    });

    this.layer.on(carto.layer.events.FEATURE_OUT, featureEvent => {
      this.props.map.closePopup();
    });

    // click on layer
    this.layer.setFeatureClickColumns(options.featureClickColumns)
    this.layer.on(carto.layer.events.FEATURE_CLICKED, featureEvent => {
        //console.log('FEATURE_CLICKED', featureEvent)
        this.props.onClick(featureEvent.data);
    });

    this.layer.on('metadataChanged', metadata => {
      if(metadata.styles.length > 0) {
        //console.log('metadata', metadata.styles[0]._buckets)
        this.props.onMetadataChange(metadata.styles[0]._buckets)
      }
    });
  }
  
  shouldComponentUpdate(nextProps) {
    if(nextProps.cartocss !== this.props.cartocss ) {
      // const layerStyle = this.layer.getStyle()
      // layerStyle.setContent(nextProps.cartocss)
    }
    //console.log('shouldComponentUpdate nextProps', nextProps.sql, 'this.props', this.props.sql)
    return nextProps.cartocss !== this.props.cartocss 
    || nextProps.visible !== this.props.visible 
    || nextProps.sql !== this.props.sql;
  }

  setVisibility = (isVisible) => {
    isVisible ? this.layer.show() : this.layer.hide();
  }

  componentWillUnmount() {
    this.setVisibility(false);
  }

  render() {
    const { visible, cartocss, sql } = this.props;
    const layerStyle = this.layer.getStyle();
    const layerQuery = this.layer.getSource();
    layerQuery.setQuery(sql);
    //console.log('render in layer', visible, cartocss, layerStyle, layerQuery);
    layerStyle.setContent(cartocss).then(() => {
     this.setVisibility(visible)
    });
    //this.setVisibility(this.props.visible)
    return null;
  }
}

export default Layer;
