import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
const useStyles = makeStyles({
  maptype: {
    'padding': '1rem',
    position: 'relative',

    background: '#000',
    'border-top-left-radius': '0.5rem',
    'border-top-right-radius': '0.5rem',
    'color': '#fff',
  }
});

export const GET_MAPTYPE = gql`
  query GetTable {
    mapType @client
  }

`;
const MapType = (props) => {
  const classes = useStyles();

  return (
    <ApolloConsumer>
    {client =>  (
      <Query query={GET_MAPTYPE}>
      {({ data, loading, error }) => {
        //console.log('GET_MAPTYPE', data)
      return data && 
        <div className={classes.maptype}>
          <Typography variant="h6" gutterBottom color="primary">
            Change map type
          </Typography>
          <ToggleButtonGroup value={data.mapType} exclusive 
          onChange={(e) => {
            client.writeData({ data: { 
              mapType: e.currentTarget.value
            } });
          }}
          >
            <ToggleButton value="light">
              Light
            </ToggleButton>
            <ToggleButton value="terrain">
              Terrain
            </ToggleButton>
            <ToggleButton value="dark">
              Dark
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
    }}
    </Query>
    )}
    </ApolloConsumer>
  )
}
export default MapType;

