import React from 'react';
import { Query } from 'react-apollo';
import Results from  '../components/results';
import ResultSingle from '../components/result-single';
import ResultSingleFullDetail from '../components/result-single-full-detail';
import ResultSingleFullDetailCloseButtons from '../components/result-single-full-detail-close-buttons';
import MapMain from '../components/map/MapMain';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import {GET_SCORES} from '../queries/queries';
import { Loading } from '../components';
import {filterArrayOfObjectByKey} from '../utils/utils';
import ScatterChartTabs from '../components/scatter-chart-tabs';
import Footer from '../components/footer';
import {makeRenderScore, sortArrayByKeyDescending} from '../utils/utils'
const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%'
  },
  result: {
    'padding': '2rem',
    'position': 'relative',
    'overflow-y': 'auto',
    'overflow-x': 'hidden',
    'height': '100vh'
  },
});

export default function ResultContainer({ table, column, value, hasFilters}) {
  const classes = useStyles();
  let size
  return (

    <Query query={GET_SCORES} variables={{ table, column, value, hasFilters}}
    >
    {({ data, loading, error, fetchMore }) => {
        if (loading) return <Loading />;
        if (error) return <p>ERROR</p>;
        //console.log('ResultContainer data', data, 'table', table, 'column', column, 'value', value)
        data.fullDetail ? size = {map: 5, detail: 7} : size = {map: 9, detail: 3 }
        if (!data.scores) {
          return <p data-testid="empty-message">No data</p>
        } else {
          let ssc_code12_string,ssc_code16_list, sql
          ssc_code16_list = filterArrayOfObjectByKey(data.scores.scores,'ssc_code16')
          if (ssc_code16_list.length > 0) {
            let where = ''
            ssc_code12_string = "('" + ssc_code16_list.join('\',\'') + "')"
            if (hasFilters) {
              where = `where greater_sydney_suburb.ssc_code16 in ${ssc_code12_string}`
              if (value.toLowerCase() === 'any') {
                where = `WHERE ${column} != 0 AND ${column} IS NOT NULL`
              }
            }

            sql = `SELECT greater_sydney_suburb.cartodb_id, greater_sydney_suburb.the_geom, greater_sydney_suburb.the_geom_webmercator, greater_sydney_suburb.ssc_code16, 
            greater_sydney_suburb.lat, greater_sydney_suburb.lon,
            greater_sydney_suburb.ssc_name16, ${table}.buy_house , 
            ${table}.score_transport, ${table}.score_amenity, ${table}.score_health, 
            ${table}.score_education, ${table}.score_employment, ${table}.score_environment, ${table}.score_community,
            ${table}.buy_unit, ${table}.rent_house, ${table}.rent_unit, 
            
            /* TIED TO SLIDER, NULL RESULTS SET TO ZERO */
            COALESCE((
              (${data.transport/100} * score_transport) + 
              (${data.amenity/100} * score_amenity) + 
              (${data.health/100} * score_health) + 
              (${data.education/100} * score_education) + 
              (${data.employment/100} * score_employment) + 
              (${data.environment/100} * score_environment) + 
              (${data.community/100} * score_community)
              ),0) as render_score
            
            FROM greater_sydney_suburb 
            INNER JOIN ${table}
            ON greater_sydney_suburb.ssc_code16 = ${table}.ssc_code16 ${where}`
          }
          //console.log('sql', sql)
          const renderScoreData = makeRenderScore(data)
          const decendingData = sortArrayByKeyDescending(renderScoreData, 'render_score')
          let detailComponent
          if(data.selected_ssc_code16) {
            if (data.fullDetail) {
              detailComponent = 
              <>
                
                <ResultSingleFullDetail decendingData={decendingData} />
                  <ScatterChartTabs data={data.scores.scores}  
                  column={column} 
                  selected_ssc_code16={data.selected_ssc_code16} />
                <ResultSingleFullDetailCloseButtons />
              </>
            } else {
              detailComponent = <ResultSingle data={data} decendingData={decendingData} />
            }
          } else {
            detailComponent = <Results data={decendingData} />
          }
          
          return (
              <Grid className={classes.container} container spacing={0}>
                <Grid item xs={size.map}>
                    <MapMain sql={sql} inputData={data} />
                </Grid>
                <Grid item xs={size.detail} className={classes.result}>
                    {detailComponent}
                    <Footer />
                </Grid>
              </Grid>
            );
          }
      }
    }
      </Query>

  );
}