import numeral from 'numeral'

export const chartOptions = (xLabel, yLabel) => {

  return {
    maintainAspectRatio: false,
    
   legend: {
      display: false,
    },
    responsive: false,
    intersect: true,
    chartArea: {
      backgroundColor: 'rgba(255, 255, 255, 1)'
    },

    scales: {
      xAxes: [{
        gridLines: {
          color: "rgba(255, 255, 255, 1)",
          lineWidth: '0.5',
        },
        ticks: {
          callback: (value) => {
              return numeral(value).format('$0,0');
          },
          fontColor: "#fff",

        },
        scaleLabel: {
          display: true,
          labelString: xLabel,
          fontColor: "#fff",
        }
      }],
      yAxes: [{
        gridLines: {
          color: "rgba(255, 255, 255, 1)",
          lineWidth: '0.5'
        },
        ticks: {
          maxTicksLimit: 6,
          fontColor: "#fff",
        },
        display: true,
        scaleLabel: {
          display: true,
          labelString: yLabel,
          fontColor: "#fff",
        }
    }]

    },
    "tooltips": {
      "enabled": true,
      "mode": "label",
      "intersect": false,
      "displayColors": false,
      "callbacks": {
        title: (tooltipItem, data) => {
          return '';
        },
        label: (tooltipItem, data) => {
          
          let item = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          //console.log('tooltipItem', tooltipItem, 'data', data, 'item', item)
          return item.suburb
        //   let item = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
        //   let total = data.datasets[tooltipItem.datasetIndex].lineTension;
        //   return `${numeral(item).format('$0,0.00')}` ;
        },
      }
    }
  }
}
export const chartHeadings = {
    'score': {
        'transport': 'Transport score',
        'amenity': 'Amenity score',
        'health': 'Health score',
        'education': 'Education score',
        'employment': 'Employment score',
        'environment': 'Environment score',
        'community': 'Community score',
        'dropdowns': {
          'transport': 'Transport score',
          'amenity': 'Amenity score',
          'health': 'Health score',
          'education': 'Education score',
          'employment': 'Employment score',
          'environment': 'Environment score',
          'community': 'Community score',
        },
        showInfoIcon: false,
        label: '',
        intro: `The relationships between the liveability features and price is complex. Although with the exception of 'Environment', the correlation is positive. That is, the better the liveability feature, the higher the house price. The relationship is particularly strong with Community, Amenity and Education. This chart lets you compare a specific liveability feature with price and see what other suburbs have your desired livability features.`
    },
    density: {
        'dwellings': 'Dwelling density',
        'persons': 'Population density',
        'dropdowns': {
          'dwellings': 'Dwelling density',
          'persons': 'Population density'
        },
        showInfoIcon: true,
        label: ' per sqHa',
        intro: 'Although not exact, there is a notable, positive correlation between density and price. That is, as either dwelling or population density increases, so too does dwelling price. The suburbs on the right hand side of the chart are <i>typically</i> developed to a higher density and housing is <i>typically</i> higher priced. You can use the chart to compare the density/price characteristics of your suburb of choice with the other suburbs in your selected region.'
    }
}  
export const makeChartData = (data, column, type, selectedScore) => {

    return {
        labels: type,
        showLines: true,
        gridLines: {
          display: true
        },
        datasets: [
          {
            fill: false,
            backgroundColor: 'rgba(255, 229, 214, 1)',
            pointBorderColor: 'rgba(255, 192, 0, 1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointRadius: 6,
            pointHitRadius: 5,
            pointHoverRadius: 10,
            pointHoverBackgroundColor: 'rgba(255, 229, 214, 1)',
            pointHoverBorderColor: 'rgba(255, 192, 0, 1)',
            pointHoverBorderWidth: 2,
            data: makeSelectedCoordinate(selectedScore, column, type)
          },
          {
            fill: false,
            backgroundColor: 'rgba(189, 215, 238, 1)',
            pointBorderColor: 'rgba(91, 157, 181, 1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointRadius: 2,
            pointHitRadius: 10,
            pointHoverRadius: 10,
            pointHoverBackgroundColor: 'rgba(189, 215, 238, 1)',
            pointHoverBorderColor: 'rgba(91, 157, 181, 1)',
            pointHoverBorderWidth: 2,
            data: makeChartCoordinate(data, column, type, selectedScore)
          },
        ]
    }

  };  

export const makeSelectedCoordinate = (selectedScore, column, type) => {
    let x = selectedScore.value[column]
    let y
    if (type === 'dwellings' || type === 'persons') {
        y = selectedScore.value[`${type}_per_ha`] 
    } else {
        y = selectedScore.value[`score_${type}`] 
    }
    const point = {
        x,
        y
    }
    //console.log('point', point)
    return [point]
}


export const makeChartCoordinate = (data, column, type, selectedScore) => {
    //console.log('makeChartCoordinate data', data, 'column', column, 'type', type, 'selectedScore', selectedScore)
    let y
    let arr = data.map(item => {
        let x = item[column]
        
        if(type === 'dwellings' || type === 'persons') {
            y = item[`${type}_per_ha`]
        } else {
            y = item[`score_${type}`]
        }
        
        const suburb = item.ssc_name16
        const ssc_code16 = item.ssc_code16
        const lat = item.lat
        const lon = item.lon
        let cord = {
            x,
            y,
            suburb,
            ssc_code16,
            lat,
            lon

        }
        // if (selectedScore.value.ssc_code16 === item.ssc_code16) {
        //     cord = null
        // }
        //console.log('cord', cord)
        return cord
    })
    //console.log('arr', arr)
    return arr.filter( n => n )
}
