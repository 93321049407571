import numeral from "numeral";

const Popup = ({
  ssc_name16,
  score_transport,
  score_amenity,
  score_health,
  score_education,
  score_employment,
  score_environment,
  score_community,
  column,
  buy_house,
  buy_unit,
  rent_house,
  rent_unit,
  hasFilters
}) => {
  const price_output = {
    buy_house: buy_house,
    buy_unit: buy_unit,
    rent_house: rent_house,
    rent_unit: rent_unit,
  };

  const [tenure, type] = column.split("_");
  let price_output_string;
  tenure === "buy"
    ? (price_output_string = `${type} price`)
    : (price_output_string = `${type} ${tenure} `);

  let median_price = "";
  if (hasFilters) {
    median_price = `<strong>Median ${price_output_string}: ${numeral(
      price_output[column]
    ).format("$0,0")}</strong>`;
  }
  return `<div class="popUp">
  <h2>${ssc_name16}</h2>
  ${median_price}
  <p><strong>Transport:</strong> ${numeral(score_transport).format(
    "0,0.00"
  )}</p>
  <p><strong>Amenity:</strong>  ${numeral(score_amenity).format("0,0.00")}</p>
  <p><strong>Health:</strong>  ${numeral(score_health).format("0,0.00")}</p>
  <p><strong>Education:</strong> ${numeral(score_education).format(
    "0,0.00"
  )}</p>
  <p><strong>Employment:</strong> ${numeral(score_employment).format(
    "0,0.00"
  )}</p>
  <p><strong>Environment:</strong> ${numeral(score_environment).format(
    "0,0.00"
  )}</p>
  <p><strong>Community:</strong> ${numeral(score_community).format(
    "0,0.00"
  )}</p>
</div>`;
};

export default Popup;
