import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { ApolloConsumer } from 'react-apollo';
import Link from '@material-ui/core/Link';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  heading: {
    'font-weight': 600,
  },
  link: {
    '&:hover': {
      'font-weight': 600,
    }
  }
});

export default function ResultList({data, heading, extraClassName, type}) {
  //console.log('data input', data)
  const classes = useStyles();
  return (
    <ApolloConsumer>
    {client => (
      <div className={classes.root + ' ' + extraClassName}>
      <Typography className={classes.heading} variant="h6" gutterBottom color={type}>
      {heading}
    </Typography>

      {data.map((item, index) =>(
        <Typography variant="body1" gutterBottom key={index}>
          <Link data-cy={`${extraClassName}-${index}`} color="textPrimary" underline="none" className={classes.link} onClick={(e) => {
              let location  = { 
                lat: item.lat, 
                lon: item.lon, 
                selected_ssc_code16: item.ssc_code16 }
              client.writeData({ data: location })
              //console.log('location', location)
              e.preventDefault()
            }
          } href="/">
            {index +1 }. {item.ssc_name16}
          </Link>
        </Typography>
      )
      )}
      </div>
    )}
    </ApolloConsumer>
);  
}
