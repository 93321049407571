import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
const useStyles = makeStyles({
  transport: {
    'margin-top': '1rem',
    'text-align': 'justify'
  },

});

export default function Transport(props) {
  const classes = useStyles();
  return (
    <div className={classes.transport}>
        <Typography variant="h5">
          Transport methodology 
        </Typography>
        <Typography variant="body1" paragraph>
          Accessibility to public transport, tram stops, train stops, ferry stops, bus stops etc.
        </Typography>
        <Typography variant="h6">
          Data source:
        </Typography>
        <Typography variant="body1" paragraph>
        <Link href="https://opendata.transport.nsw.gov.au/dataset/timetables-complete-gtfs" target="_blank" rel="noreferrer">Transport for NSW - General Transit Feed Specification</Link> (GTFS).  
        </Typography>
        <Typography variant="h6">
          Method:
        </Typography>
        <Typography variant="body1" paragraph>
          1. Consider the proximity and the frequency of every public transport service. The bus/train/ferry schedule for Monday to Thursday is used and Friday excluded, due to the Friday schedule varying considerably for different stops. Incorporating this would make the model overly complex.
        </Typography>
        <Typography variant="body1" paragraph>
          2. If, at a given stop there is no services before 7:30 a.m., or after 6:30 p.m., or if average waiting time between 7:30 a.m. and 6:30 p.m. is greater than 30 minutes, then this stop is categorised as an outlier and filtered out from the access calculation. 
          <i>This step is derived from the RMIT liveability model for transport criteria for acceptable service frequency and timing. Arundel, Jonathan, et al. "Creating liveable cities in Australia: Mapping urban policy implementation and evidence-based national liveability indicators." (2017).</i>
        </Typography>
        <Typography variant="body1" paragraph>
          3. Calculate the estimated walking time from each SA1 centroid to this stop, and then filter out any route where the duration is longer than 30 minutes.
        </Typography>
        <Typography variant="body1" paragraph>
          4. Set a threshold for 10 minutes for measuring the better scores. This is roughly equivalent to the 20% quantile for all suburb scores. Then a “cleaned score ” function is used. 
          <i>The ‘cleaned score’ function classes suburbs into two (or more) categories. A different ranking method is used in each class. For example, the higher rated class can be ranked using the number of facilities. The lower rated class is then ranked using Σ(1/distance to facility), Scoring is different as there is a different score range for each class, because range is based on the number of suburbs in each class.</i>
        </Typography>
        <Typography variant="body1" paragraph>
          5. The weighted score for this location is then calculated as: 1/estimated_walking_time * stop_freq_trips_per_hr
        </Typography>
        <Typography variant="body1" paragraph>
          6. A log minmax function is then applied to the score to scale it to between the range defined in the cleaned score function (i.e. for each subclass).
        </Typography>
    </div>
  );
}
