import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    disclaimer: {
      'text-align': 'justify'
    }
});

export default function AboutTool(props) {
  const classes = useStyles();

  return (
    <div className={classes.disclaimer}>
        <Typography variant={props.heading}>
          Disclaimer
        </Typography>
        <Typography variant="body1" paragraph>
          While all due care has been taken to ensure that the content of this website is accurate and current, it may contain errors or omissions. No legal responsibility is accepted for any loss, damage, cost or expense (whether direct or indirect) incurred as a result of a person taking action in respect to any representation, statement, or advice referred to on this site.
        </Typography>
        <Typography variant="body1" paragraph={false}>
          Copyright for the data presented on this site vests with the data providers as specified.
        </Typography>
    </div>
  );
}
