import gql from 'graphql-tag';


export const typeDefs = gql`
  extend type Query {
    table: String!
    column: String!
    value: String!
  }
`;

export const resolvers = {
  Launch: {

  },
  Mutation: {

  },
};
