import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import urban from '../assets/images/urban-mosaic.png';
import AboutTool from './information/about-tool';
import Disclaimer from './information/disclaimer';

const useStyles = makeStyles({
  header: {
    display: 'grid',
    'grid-template-columns': '32rem 1fr',
    'border-bottom': '1px solid #fff',
    'padding-bottom': '1rem',
    'margin-bottom': '1rem'
  },
  header__image: {
    'width': '30rem'
  },
  header__link: {
    'text-decoration': 'none'
  },
  dialog__action: {
    'flex-direction': 'column',
    'justify-content': 'center',
    'margin': '0 0 2rem'
  },


});

export default function WelcomeDialog() {
  const classes = useStyles();
  const hasWelcomeDialog = localStorage.getItem('welcomeDialog') === "false"? false : true
  const [welcomeDialog, setWelcomeDialog] = useState(hasWelcomeDialog)
  const [isChecked, setIsChecked] = useState(false)

  const handleChange = (event, stateValue) => {
    setIsChecked(true)
  }

  return (

    <Dialog
          open={welcomeDialog}
          onClose={() => {
            setWelcomeDialog(false)
          }}
          fullWidth={true}
          maxWidth={'md'}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll="paper"
    >
      <DialogContent className={classes.dialog__content}>
        <div className={classes.header}>
              <a href="/" className={classes.header__link}>
                <img src={urban} alt="urban mosaic" className={classes.header__image} />
              </a>
              <Typography variant="h6">
                Welcome to City Life Analytics: a joint project between the Urban Development Institute of Australia NSW and Geografia.
              </Typography>
        </div>
        <AboutTool heading="h6"/>
        <Disclaimer heading="h6" />
      </DialogContent>
      <DialogActions className={classes.dialog__action}>
        <FormControlLabel
          value="welcomeDialog"
          control= {
            <Checkbox color="primary" checked={isChecked} 
              onChange={handleChange}
            />
          }
          label="Don't show again"
          labelPlacement="end"
        />
        <Button variant="contained" color="primary" data-cy="start"
          onClick={(clickData) => {
            setWelcomeDialog(false)
            if(isChecked) {
              localStorage.setItem('welcomeDialog', false);
            }
        }}
        >
          Start
        </Button>
      </DialogActions>
    </Dialog>

  );
}
