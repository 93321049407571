import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import ResultSingleCloseButton from './result-single-close-button';
import ResultSingleFullDetailButton from './result-single-full-detail-button';

import {getScoreObject, makeScoresRating, makeScoresRatingString, getScoreColor, mapColors} from '../utils/utils';
import numeral from 'numeral';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  heading: {
    'margin-left': '10px',
    'fontSize': '18px'
  }
});

function ResultSingle({data, decendingData}) {
  const classes = useStyles();
  
  const selectedScore = getScoreObject(decendingData, parseInt(data.selected_ssc_code16))
  //console.log('decendingData', decendingData, 'data', data, 'selectedScore', selectedScore)
 
  const renderScore = selectedScore.value.render_score
  //console.log('renderScore', renderScore)
  const scoresRating = makeScoresRating(selectedScore.value)
  const scoresRatingString = makeScoresRatingString(scoresRating)
  //console.log('scoresRating',scoresRating,'scoresRatingString', scoresRatingString)
  const buckets = JSON.parse(localStorage.getItem('buckets'))
 
  const scoreColor = getScoreColor(buckets, renderScore)
  //console.log('ResultSingle buckets', buckets, 'scoreColor', scoreColor, 'selectedScore', selectedScore)
  const scoreStyle = {
    color: scoreColor
  }
  return data && (
    <div className={classes.root}>
      <Typography variant="h4" gutterBottom>
        {selectedScore.value.ssc_name16}
      </Typography>
      <Typography variant="body1" gutterBottom>
      <strong>SUITABILITY RATING: </strong><span style={scoreStyle}> {mapColors[scoreColor].toUpperCase()}</span>
      </Typography>
      <Typography variant="body1" gutterBottom data-cy="result-conclusion">
        Based on your liveability feature and housing preferences, <strong>{selectedScore.value.ssc_name16}</strong> is <span style={scoreStyle}> {mapColors[scoreColor]} </span> 
        in terms of suitability.
      </Typography>
      <Typography variant="button" gutterBottom>
      <span data-cy="rank" style={scoreStyle}>RANK: #{selectedScore.index + 1}</span>
      </Typography>
      {
        Object.entries(selectedScore.value).map(([key, value], index) => {
          if(key.includes('score_')) {
                  const [, scoreValue] = key.split('_')
                  //console.log(`value ${value} data ${data[scoreValue]}`);
                  return <Typography variant="body2" gutterBottom key={index} data-cy="result-single"> 
                    {scoreValue.toUpperCase()} : {numeral(value).format('0,0.00')} points
                  </Typography>
                }
          else {
            return null
          }
        })
      }
      
      <Typography variant="h6" gutterBottom color="primary">
        Summary
      </Typography>

      <Typography variant="body1" gutterBottom data-cy="result-summary">
        The suburb of <strong>{selectedScore.value.ssc_name16}</strong> {scoresRatingString}.
      </Typography>

      <ResultSingleFullDetailButton suburb={selectedScore.value.ssc_name16} />
      <ResultSingleCloseButton />
    </div>
  );
}
export default ResultSingle

