import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
const useStyles = makeStyles({
  mapLGA: {
    'padding': '1rem',
    position: 'relative',

    background: '#000',
    'border-top-left-radius': '0.5rem',
    'border-top-right-radius': '0.5rem',
    'color': '#fff',
  },
  toggleButton: {
    width: '50%'
  },
  close: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem'
  },

});

export const GET_MAPLGA = gql`
  query GetMapLGA {
    mapLGA @client
  }

`;
const MapLGA = (props) => {
  const classes = useStyles();
  const onUpdate = (e) => {
    if (typeof props.onUpdate === 'function') {
      props.onUpdate(e);
    }
  }

  return (
    <ApolloConsumer>
    {client =>  (
      <Query query={GET_MAPLGA}>
      {({ data, loading, error }) => {
        //console.log('GET_MAPLGA', data)
      return data && 
        <div className={classes.mapLGA}>
          <Close className={classes.close} onClick={onUpdate}/>
          <Typography variant="h6" gutterBottom color="primary">
            LGA Boundaries
          </Typography>
          <ToggleButtonGroup value={data.mapLGA} exclusive 
          onChange={(e) => {
            client.writeData({ data: { 
              mapLGA: e.currentTarget.value === 'true' ? true : false
            } });
          }}
          >
            <ToggleButton value={false} className={classes.toggleButton}>
              Off
            </ToggleButton>
            <ToggleButton value={true} className={classes.toggleButton}>
              On
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
    }}
    </Query>
    )}
    </ApolloConsumer>
  )
}
export default MapLGA;

