import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  futureiterations: {
    'margin-top': '1rem',
    'text-align': 'justify'
  },

});

export default function FutureIterations(props) {
  const classes = useStyles();

  return (
    <div className={classes.futureiterations}>
      <Typography variant="h5">
        Future Iterations
      </Typography>
      <Typography variant="body1" paragraph>
        Future iterations of this application could integrate scenario modelling features that show how different infrastructure roll out scenarios affect the liveability of a particular suburb. This functionality would be very useful for planners looking for the most effective way to improve a suburb's liveability and is a fundamental feature of the City of Melbourne's City Analytics decision support tool developed by Geografia.
      </Typography>
    </div>
  );
}
