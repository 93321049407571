import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import About from './information/about';
import Instructions from './information/instructions';
import Technical from './information/technical';
import FutureIterations from './information/future-iterations';
import { ApolloConsumer } from 'react-apollo';
import { Query } from 'react-apollo';
import TabContainer from './tabContainer';
import {GET_DIALOG} from '../queries/queries';

const useStyles = makeStyles({
  header: {
    display: 'grid',
    'grid-template-columns': '2fr 1fr'
  },
  header__link: {
    'text-decoration': 'none'
  },
  header__image: {
    width: '15rem',
    'justify-self': 'start'
  },
  icon: {
    'fontSize': '4.5rem',
    'justify-self': 'end',
    'color': '#fff'
  },
  dialog__content: {
    height: '80vh'
  }
});

export default function HeaderDialog(props) {
  const classes = useStyles();

  return (
    <ApolloConsumer>
    {client =>  (
      <Query query={GET_DIALOG}>
      {({ data, loading, error }) => {
//console.log('header', data)
        return data &&  
          <Dialog
          open={data.dialogOpen}
          onClose={() => {
            client.writeData({ 
              data: { 
                dialogOpen: false,                    
              } 
            });
          }}
          fullWidth={true}
          maxWidth={'lg'}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll="paper"
        >
          <DialogContent className={classes.dialog__content}>
              <AppBar position="static" color="default">
              <Tabs value={data.dialogTab}
              onChange={(event, newValue) => {
                client.writeData({ 
                  data: {
                    dialogTab: newValue,
                  } 
                });
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth">
                <Tab label="About" />
                <Tab label="Instructions" />
                <Tab label="Technical" />
                <Tab label="Future Iterations" />
              </Tabs>
            </AppBar>
            {data.dialogTab === 0 && <TabContainer><About /></TabContainer>}
            {data.dialogTab === 1 && <TabContainer><Instructions /></TabContainer>}
            {data.dialogTab === 2 && <TabContainer><Technical /></TabContainer>}
            {data.dialogTab === 3 && <TabContainer><FutureIterations /></TabContainer>}
          </DialogContent>
          <DialogActions>
                <Button color="primary"
                  onClick={(clickData) => {
                  client.writeData({ 
                    data: { 
                      dialogOpen: false,                    
                    } 
                  });
                }}
                >
                  Close
                </Button>
              </DialogActions>
          </Dialog>
      }}
      </Query>
        )}
    </ApolloConsumer>
  );
}
