import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/styles';
import ResultList from './result-list'
import Typography from '@material-ui/core/Typography';
import Suggestion from './suggestion';
const useStyles = makeStyles({
  root: {
    width: '100%'
  }
});

function Results({data}) {
  
  const classes = useStyles();
  const totalDisplayData = 5
  const topData = data.slice(0, totalDisplayData)
  const bottomData = data.slice(Math.max(data.length -  totalDisplayData, 0))
  //console.log('Results data', data, 'bottomData', bottomData, 'topData', topData, 'suggestionData', suggestionData)
  return  (
    <div className={classes.root}>
    {
      data.length > 0 ? (
        <Fragment>
          <ResultList data={topData} heading="Most suitable suburbs:" type="primary" extraClassName="top-suburbs" />   
          {bottomData.length > 0 && <ResultList data={bottomData} heading="Least suitable suburbs:" type="secondary" extraClassName="bottom-suburbs"/> } 
        </Fragment>
      ) : (
        <Fragment>
          <Typography variant="h6">
            No suburbs
          </Typography>
        </Fragment>
      )
    }

    <Suggestion suggestionData={data}/>
    </div>
  );
}
export default Results