import React from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';

export default function renderSuggestion (suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.ssc_name16, query);
  const parts = parse(suggestion.ssc_name16, matches);
  return (
    <MenuItem selected={isHighlighted} component="div">
        {parts.map(part => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
    </MenuItem>
  );
}
