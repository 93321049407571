import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Query } from 'react-apollo';
import { Loading } from '../components';
import { ApolloConsumer } from 'react-apollo';
import {GET_SSC_CODE16} from '../queries/queries';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles({
  closeButton: {
    'text-align': 'center',
    'margin': '2rem'
  },

});

function ResultSingleCloseButton({sql, props}) {
    const classes = useStyles() 

    return (
        <ApolloConsumer>
        {client => (
          <Query query={GET_SSC_CODE16}>
            {({ data, loading, error }) => {
              if (loading) return <p><Loading/></p>;
              if (error) return <p>ERROR: {error.message}</p>;
              return (
                <div className={classes.closeButton}>
                  <Button variant="contained"  color="primary" data-cy="close-button"
                  onClick={(e) => {
                    client.writeData({ data: { selected_ssc_code16: null, } });
                  }}
                  >Back</Button>
                </div>
                )
            }}
          </Query>
        )}
        </ApolloConsumer>
    );   
}
export default ResultSingleCloseButton