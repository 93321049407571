import deburr from 'lodash/deburr';

export const setCurrentSlider = (data, payload) => {
    const max = 100
    
    const updatedSliders = data.sliders.map(item => {
        if (item.labelId === payload.labelId) {
            return {
                ...item,
                value: payload.value
            }
        }
        return item
    })
    //console.log('updatedSliders', updatedSliders)
    const updatedTotal = max - updatedSliders.map(item => {
        return item.value
    }).reduce((total, number) => {
            return total + number
    })
    //console.log('updatedTotal', updatedTotal)
    const  { sliders, totalPoint, ...remaining} = data
    //console.log('remaining', remaining)
    return {
        sliders: updatedSliders,
        totalPoint: updatedTotal,
        ...remaining
    }

}

export const filterArrayOfObjectByKey = (array, key) => {
    return array.map(item => {
        return item[key]
    })
}

export const getPointsBalance = (transport, amenity, health, education, employment, environment, community) => {
    const totalPointMax = 100
    const remainingPoints = totalPointMax - transport - amenity - health - education - employment - environment - community
    return {
        remainingPoints,
        disabled: remainingPoints === 0 ? false: true 
    }
}
export const makeRenderScore = (object) => {
    return object.scores.scores.map(item => {
        let render_score = 0
        Object.keys(item).forEach( (arrayItem) => {
            if(arrayItem.includes('score')) {
                const type = arrayItem.split('_')[1]
                //console.log(arrayItem, item[arrayItem], type); 
                render_score = render_score + (item[arrayItem] * object[type])/100
            }
        });
        return {
            ...item,
            render_score
        }
    })
}

export const sortArrayByKeyAscending = (array, key) => {
    return array.concat().sort((a, b) => a[key] - b[key])
}

export const sortArrayByKeyDescending = (array, key) => {
    return array.concat().sort((a, b) => b[key] - a[key])
}

export const getScoreObject = (array, selected_ssc_code16) => {
    const index = array.findIndex(item => item.ssc_code16 === selected_ssc_code16)
    const returnObject = {
        index,
        value: array[index]
    }
    //console.log('returnObject', returnObject)
    return returnObject
}

export const makeScoresRating = (obj) => {
    let well = [], average = [], poorly = []
    Object.entries(obj).forEach( ([key, value]) => {
        //console.log(`${key}: ${value}`)
        if(key.match(/^score_/g)) {
            if (value > 6) {
                well.push(key.split('_')[1])
            } else if (value >= 4 && value <= 6) {
                average.push(key.split('_')[1])
            } else {
                poorly.push(key.split('_')[1])
            } 
        }
    })
    return {
        well,
        average,
        poorly
    }
}

//scored well for employment, environment indicators
export const makeScoresRatingString = (obj) => {
    let result = [], tmp = ''
    Object.entries(obj).forEach( ([key, value]) => {
        if (value.length > 0) {
            
            if (key === 'average') {
                tmp  = `was ${key} for ${value.join(', ')} indicators` 
            } else {
                tmp  = `scored ${key} for ${value.join(', ')} indicators` 
            }
            //console.log('key', key, 'value', value, 'length', value.length, 'tmp', tmp)
        }
        if (tmp !== '') {
            result.push(tmp)
            tmp = ''
        }
    })
    if (result.length === 1) {
        return result[0]
    } else if (result.length ===2) {
        return result.join(' and ')
    } else{
        return `${result[0]}; ${result[1]} and ${result[2]}`
    }
    
}


export const getScoreColor = (buckets, score) => {
    const selectedBucket = buckets.filter(item => {
        return score >= item.min && score <= item.max
    })
    //console.log('selectedBucket', selectedBucket, 'buckets', buckets, 'score', score)
    if(selectedBucket.length > 0) {
        return selectedBucket[0].value
    } else {
        if(score < buckets[0].min) {
            return buckets[0].value
        }
        if(score > buckets[buckets.length-1].max) {
            return buckets[buckets.length-1].value
        }
    }
    
}

export const mapColors ={
    '#d53e4f': 'Undesirable',
    '#fc8d59' : 'Very Poor',
    '#fee08b' : 'Poor',
    '#ffffbf' : 'Average',
    '#e6f598' : 'Good',
    '#99d594' : 'Very Good',
    '#3288bd': 'Excellent'
    
}


export const getSscCodeByName = (list, name) => {
    return list.filter(item => {
        return item.ssc_name16 === name
    })[0]
}

export const getSuggestions = (suggestionData, value) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
  
    return inputLength === 0
      ? []
      : suggestionData.filter(suggestion => {
          const keep =
            count < 5 && suggestion.ssc_name16.slice(0, inputLength).toLowerCase() === inputValue;
  
          if (keep) {
            count += 1;
          }
  
          return keep;
        });
  }