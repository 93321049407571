import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

const useStyles = makeStyles({
  formControl: {
    margin: '1rem',
  },
});

export const GET_COLUMN = gql`
  query GetColumn {
    column @client
    disabled @client
    hasFilters @client
  }

`;
export default function Tenure() {
  const classes = useStyles();
  const theRef = useRef()

  return (
      <Query query={GET_COLUMN}>
        {({ data, client }) => {
          //console.log('Tenure data', data)
          if (!data.column) {
            return <p data-testid="empty-message">No data</p>
          } else {
            const [tenure, property_type] = data.column.split('_')
            return (
              <FormControl variant="outlined" className={classes.formControl} disabled={!data.hasFilters || data.disabled}>
                  <InputLabel ref={theRef} htmlFor="outlined-type">Tenure
                  </InputLabel>
                  <Select
                  value={tenure}
                  onChange={(e) => {
                      client.writeData({ 
                          data: { 
                              column:`${e.target.value}_${property_type}`,
                              value: "Any",
                              selected_ssc_code16: null,
                              fullDetail: false
                              } 
                          });
                      }}
                  input={
                      <OutlinedInput
                      labelWidth={50}
                      name="type"
                      id="outlined-type"
                      />
                  }
                  >
                  <MenuItem value="buy">Buy</MenuItem>
                  <MenuItem value="rent">Rent</MenuItem>
                  
                  </Select>
              </FormControl>
            )
          }

        }}
      </Query>

  );

}
