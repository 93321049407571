import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Switch from '@material-ui/core/Switch';

export const GET_FILTERS = gql`
  query GetFilters {
    hasFilters @client
  }
`;

export default function Switches() {
  return (
    <Query query={GET_FILTERS}>
    {({ data, loading, error, client }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>ERROR: {error.message}</p>;
      return data && 
      
        <Switch id="hasFilters"
          checked={data.hasFilters}
          onChange={(e) => {
            //console.log('e', e.target.checked)
            client.writeData({ 
              data: { 
                hasFilters: e.target.checked,
                column: 'buy_house',
                value: "Any",
                selected_ssc_code16: null,
                lat: null,
                lon: null,
                fullDetail: false
                } 
              });
            }}
          color="primary"
          name="checked"
          value={data.hasFilters}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      
    }}
  </Query>
  );
}
