import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import AboutTool from './about-tool';
import Disclaimer from './disclaimer';

const useStyles = makeStyles({
  about: {
    'margin-top': '1rem',
    'text-align': 'justify'
  },

});

export default function About(props) {
  const classes = useStyles();

  return (
    <div className={classes.about}>
       <AboutTool heading="h5" />
        <Typography variant="h5">
            About City Life Labs
        </Typography>
        <Typography variant="body1" paragraph>
          City Life Labs is a collaborative research program focused on harnessing digital technology and cutting-edge applied research techniques to help create more Liveable, Affordable and Connected cities. Over time, new layers will be added to the tool. This will gradually enhance our understanding of liveability in Greater Sydney and how it relates to services, amenities and the environment. In time the tool will be rolled out for other cities and towns across Australia.        </Typography>
        <Typography variant="h5">
            About Geografia
        </Typography>
        <Typography variant="body1" paragraph>
            Geografia is a data analytics firm formed in Australia in 2006. The company was founded to bring together the techniques of complex systems analysis, statistical modelling and data visualisation and apply them to decision-making about our cities, towns and regions. Geografia’s work is increasingly in the digital domain, with online decision-support tools (such as City Life Analytics and Spendmapp – Spendmapp.com.au ), data visualisation tools (such as the WA Government's Search Diversity website) and scenario modelling tools (such as the City of Melbourne's City Analytics tool).        
        </Typography>
        <Typography variant="body1" paragraph>
            In 2018, Geografia was one of eight finalists invited to present their proposal at the City Life Labs Pitch Event. Judged by three independent judges and the UDIA audience, Geografia's City Analytics proposal was one of two winners (alongside the University of Wollongong). City Analytics was refined into the City Life Analytics web tool.        
        </Typography>
        <Disclaimer heading="h5" />
        
    </div>
  );
}
