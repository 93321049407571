import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/lab/Slider';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
const useStyles = makeStyles({

    container: {
      'background': '#000',
      'padding': '0 1rem 1.5rem',
      display: 'grid',
      'grid-template-columns': '0.2rem 1fr 0.2rem',
      'grid-template-rows': '2rem 2rem',
      'grid-gap': '1.5rem',
      'border-bottom-left-radius': '0.5rem',
      'border-bottom-right-radius': '0.5rem',

    },
    heading: {
      'grid-column': '1 /span 12'
    },
    light: {
      color: '#fff',
      'margin-left': '0.5rem'
    },
    dark: {
      color: '#fff',
      opacity: 0.7,
      'margin-right': '0.5rem'
    },
    slider: {
      'grid-column': '2 /span 10',
      'margin-top': '1.2rem',
      'margin-left': '1.2rem'
    }
});
export const GET_MAPOPACITY = gql`
  query GetTable {
    mapOpacity @client
  }

`;

export default function MapOpacity(props) {

  const labelId = props.labelId || 'label'
  const min = props.min || 0
  const max = props.max || 100
  
  const step = props.step || 1
  const classes = useStyles();

  return (
    <ApolloConsumer>
    {client =>  (
      <Query query={GET_MAPOPACITY}>
      {({ data, loading, error }) => {
      return data && 
        <div className={classes.container}>
          <Typography className={classes.heading} variant="h6" color="primary">
            Layer transparency</Typography>
            <VisibilityOff className={classes.dark}/>
            <Slider
              className={classes.slider}
              value={data.mapOpacity}
              min={min}
              max={max}
              step={step}
              aria-labelledby={labelId}
              onChange={(e, value) => {
                //console.log('e', e, value)
                client.writeData({ data: { 
                  mapOpacity: value
                } });
              }}
            />
            <Visibility className={classes.light} />
        </div>
      }}
      </Query>
      )}
    </ApolloConsumer>
  );
}
