import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  technicalHeading: {
    'margin-top': '1rem',
    'text-align': 'justify'
  },

});

export default function echnicalHeading(props) {
  const classes = useStyles();

  return (
    <div className={classes.technicalHeading}>

        <Typography variant="body1" paragraph>
          The scores that suburbs receive for each preference feature are derived through different models. These are described below.  All scores are aggregated directly at the suburb level, not by local government area.
        </Typography>
        <Typography variant="body1" paragraph>
          <i>Note for all indicators, we trimmed out a suburb if it has population less than 200 and its driving distance to city centre is longer than 75km (80% quantile of all suburbs’ distance). There are 856 suburbs remaining.</i>
        </Typography>

    </div>
  );
}
