import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Dot from '@material-ui/icons/FiberManualRecord';
const useStyles = makeStyles({
  community: {
    'margin-top': '1rem',
    'text-align': 'justify'
  },
  dot: {
    'font-size': '1rem',
    'color': '#fff',
    'margin': '0 1rem',

  }
});

export default function Community(props) {
  const classes = useStyles();

  return (
    <div className={classes.community}>
        <Typography variant="h5">
          Community methodology
        </Typography>
        <Typography variant="body1" paragraph>
          This is a straightforward accessibility ranking quantifying the number of facilities/services within a specified distance threshold. There are three Community sub-indexes:
        </Typography>
        <Typography variant="body1" paragraph>
          1. Civic engagement: travel distance from SA1 centroid to places of worship, community centres and social centres, within 45 min walking time.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Emergency services: total number of emergency services (ambulance station, police station, fire station and SES stations). The travel distance from SA1 centroid within a suburb to a certain service must be within 45min walking time.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Crime rate: total number of crime incidents reported from January 2015 to September 2018.
        </Typography>
        <Typography variant="h6">
          Data source:
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /><Link href="https://www.openstreetmap.org" target="_blank" rel="noreferrer">Open Street Map</Link>
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> 2016 ABS Census data, LANP 4-digit level
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} />2016 ABS Census data, ANCP ancestry multi response
        </Typography>
        <Typography variant="body1" paragraph>
        <Dot className={classes.dot} /> <Link href="https://www.bocsar.nsw.gov.au/Pages/bocsar_datasets/Datasets-.aspx" target="_blank" rel="noreferrer">Bureau of Crime Statistics and Research (BOCSAR)</Link>
        </Typography>
        <Typography variant="h6">
          Method:
        </Typography>
        <Typography variant="body1" paragraph>
          A network analysis is applied and each category is equally weighted, summed up and then a minmax scale is applied to rescale to between zero and 10.        
        </Typography>
    </div>
  );
}
