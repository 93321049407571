import React, {useRef, useState, useEffect, Fragment} from 'react';
import cartoclient, {mapConfig, setMapOpacity} from './config';
import Layer from './Layer';
import { Map, TileLayer, ZoomControl, Marker } from 'react-leaflet';
import { makeStyles } from '@material-ui/styles';
import { ApolloConsumer } from 'react-apollo';
import Legend from './Legend';
import Setting from './Setting';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

// React Leaflet not able to import marker, need to use this hack https://github.com/PaulLeCam/react-leaflet/issues/453
import L from 'leaflet';


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
const useStyles = makeStyles({
  mapContainer: {
    position: 'relative',
    width: '100%',
    height: '100vh',
  },
  themap: {
    'height': '100vh',
    'z-index': '0'
  },
});

export const GET_CLIENT_DATA = gql`
  query GetClientData {
    mapType @client
    mapLGA @client
    mapOpacity @client
    column @client
    hasFilters @client
  }

`;
function MapMain({sql, inputData}) {
    const classes = useStyles() 
    const mapRef = useRef()
    const [map, setMap] = useState(undefined)
    const [theMaker, setTheMarker] = useState([inputData.lat, inputData.lon])
    const bounds = [[-35.5, 152.39],[-32, 148.58]]
    const [zoom, setZoom] = useState(mapConfig.zoomLevel)
    const [center, setCenter] = useState(mapConfig.mapCenter)

    useEffect(() => {
      //console.log('Map sql:', sql)
        setMap(mapRef)
        setTheMarker([inputData.lat, inputData.lon])
        if(inputData.lat !== null && inputData.lon!==null) {
          if (inputData.fullDetail) {
            //console.log('here', inputData.fullDetail, 'inputData.lat', inputData.lat, 'inputData.lon', inputData.lon)
            setZoom(13)
            //TODO: as the detail panel move to the left, add lon so that map move to center
            // Need to find out how parent component should know the set its container size
            setCenter([inputData.lat, inputData.lon + 0.03])
          } else {
            setZoom(11)
            setCenter([inputData.lat, inputData.lon])
          }
          
        }  else {
          setZoom(mapConfig.zoomLevel)
          setCenter(mapConfig.mapCenter)
        }
        
    },[sql, inputData.lat, inputData.lon, inputData.fullDetail]);

    const addMarker = (e) => {
      //console.log('e', e)
      //setTheMarker(e.latlng)
      //setTheMarker([e.latlng.lat, e.latlng.lng])

    }
    return (
        <ApolloConsumer>
        {client =>  (
          <Query query={GET_CLIENT_DATA}>
          {({ data, loading, error }) => {
            //console.log('main map ', data, 'zoom', zoom)
          const mapCartoCss = setMapOpacity(data.mapOpacity, inputData.scores.total_rows)
          return data && 
              <div className={classes.mapContainer}>
              <Map ref={mapRef} className={classes.themap} center={center}
              zoom={zoom} zoomControl={false} 
              minZoom={mapConfig.minZoom}
              maxBounds={bounds}
              onClick={addMarker}
              >
                <TileLayer url={mapConfig.tileNoLabel[data.mapType]} />
                {map && 
                  <Fragment>
                    <Layer 
                      map={map.current.leafletElement}        
                      sql={mapConfig.layers.sydney.sql}
                      cartocss={mapConfig.layers.sydney.cartocss}
                      client={cartoclient}
                      visible={true} 
                      options={mapConfig.layers.sydney.options} />

                    {sql && <Layer
                      map={map.current.leafletElement}        
                      sql={sql}
                      cartocss={mapCartoCss}
                      client={cartoclient}
                      visible={true} 
                      options={mapConfig.layers.sydneySuburb.options} 
                      column={data.column}
                      hasFilters={data.hasFilters}
                      onMetadataChange={(data) => {
                        localStorage.removeItem('buckets')
                        localStorage.setItem('buckets', JSON.stringify(data));
                      }} 
                      onClick={(clickData) => {
                        //console.log('click data', clickData)
                        client.writeData({ 
                          data: { 
                            selected_ssc_code16: clickData.ssc_code16,
                            lat: clickData.lat,
                            lon: clickData.lon                    
                         } });
                      }} />}
                    <Layer
                      map={map.current.leafletElement}        
                      sql={mapConfig.layers.lga.sql}
                      cartocss={mapConfig.layers.lga.cartocss}
                      client={cartoclient}
                      visible={data.mapLGA} 
                      options={mapConfig.layers.lga.options} /> 
                      
                    {sql && <Layer
                      map={map.current.leafletElement}        
                      sql={sql}
                      cartocss={mapConfig.layers.sydneySuburbEmpty.cartocss}
                      client={cartoclient}
                      visible={true} 
                      options={mapConfig.layers.sydneySuburb.options}
                      column={data.column}
                      hasFilters={data.hasFilters}
                      onMetadataChange={(data) => {
                        localStorage.removeItem('buckets')
                        localStorage.setItem('buckets', JSON.stringify(data));
                      }} 
                      onClick={(clickData) => {
                        //console.log('click data', clickData)
                        client.writeData({ 
                          data: { 
                            selected_ssc_code16: clickData.ssc_code16,
                            lat: clickData.lat,
                            lon: clickData.lon                    
                         } });
                      }} />}
                  </Fragment>
                }

                <ZoomControl position="bottomright" />
                { theMaker[0] !== null && theMaker[1] !== null &&
                <Marker position={theMaker}>
                </Marker>
               }
               <Legend />
               <Setting />
               </Map>
             </div>  

            }}
            </Query>

        )}

        </ApolloConsumer>
    );   
}
export default MapMain
