import React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles({
  instructions: {
    "margin-top": "1rem",
    "text-align": "justify",
  },
});

export default function Instructions(props) {
  const classes = useStyles();

  return (
    <div className={classes.instructions}>
      <Typography variant="h5">
        Step 1: Select preferred region - what region are you most interested
        in?
      </Typography>
      <Typography variant="body1" paragraph>
        The first step filters out the group of suburbs you want to include. As
        well as "All Suburbs", the dropdown consists of five other options:
        "Western City", "Central City", "Eastern City", "North District" and
        "South District". These are the regions used in the
        <Link
          href="https://www.greater.sydney/district-plans"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Greater Sydney Commission’s five District Plans
        </Link>&nbsp;
        (these are 20-year plans designed to bridge regional and local
        planning).
      </Typography>
      <Typography variant="h5">
        Step 2: Weight liveability features – what is important to you?
      </Typography>
      <Typography variant="body1" paragraph>
        The next step is to allocate weightings to the seven liveability
        indicators below. You have 100 preference points to allocate. If
        something is more important to you, then give it a higher weighting by
        dragging the slider to the right. Dragging it to the left gives it a
        lower weighting, which means it has less effect on the overall score.
        This is all about trade-offs and you finding the mix of liveability
        features that best match what is important to you.
      </Typography>
      <Typography variant="body1" paragraph>
        Whenever you are happy with the mix, press the Submit button. Remember,
        it will only work if you have allocated all 100 points. The map will
        then show you how each suburb rates according to those indicators. If
        you want to reset any or all of the indicators press the red crosses or
        the Reset button.
      </Typography>

      <Typography variant="h5">
        Step 3: Choose housing preferences - buy or rent and at what price?
      </Typography>
      <Typography variant="body1" paragraph>
        You can also filter for price and the tenure of housing in each suburb.
      </Typography>
      <Typography variant="body1" paragraph>
        To use this filter:
      </Typography>
      <Typography variant="body1" paragraph>
        1. Select whether you want to <strong>Buy</strong> or{" "}
        <strong>Rent</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        2. Select dwelling type (<strong>House</strong> or <strong>Unit</strong>
        )
      </Typography>
      <Typography variant="body1" paragraph>
        Select the median price you are willing to pay (purchase price or weekly
        rent). If you are buying, the amount is the maximum total price of the
        property. If you are renting, this price is the maximum amount per week
        to be paid. Numbers are based on median property and rental prices from
        CoreLogic. The price increments are the same as those used by most real
        estate search websites in Australia. Note that there may be no recent
        sales data in some suburbs, and so they will remain dark after you have
        made your choice.
      </Typography>
    </div>
  );
}

// <Typography variant="body1" paragraph>
// </Typography>
