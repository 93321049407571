import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Dot from '@material-ui/icons/FiberManualRecord';
const useStyles = makeStyles({
  density: {
    'margin-top': '1rem',
    'text-align': 'justify'
  },
  dot: {
    'font-size': '1rem',
    'color': '#fff',
    'margin': '0 1rem',

  }
});

export default function Community(props) {
  const classes = useStyles();

  return (
    <div className={classes.density}>
        <Typography variant="h5">
          Density methodology
        </Typography>
        <Typography variant="body1" paragraph>
          Residential density by suburb was measured using both the Estimated Resident Population (ERP) for 2017 by SA1 and the dwelling count for the 2016 census by suburb. Residential land (in hectares) by suburb was estimated using the Land Zoning Dataset from the Department of Planning, Industry and Environment (DPIE). All land zones not related to residential activities were filtered from the main dataset. The remaining residential subset of the Land Zoning dataset was then compared to the residential ABS mesh blocks (filtered by residential land type) to validate the spatial extent of the zoning subset. SA1-ERP was aggregated to suburb level by using the official ABS population-weighted correspondence.        </Typography>
        <Typography variant="body1" paragraph>
          Population and dwelling densities were calculated by dividing the ERP and Dwelling counts by suburb to the total residential land area per suburb.
        </Typography>

        <Typography variant="h6">
          Data source:
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} />Environment Planning Instrument - Land Zoning - NSW DPIE
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> ABS SSC 2016 boundaries
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> ABS mesh blocks 2016
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> ABS ERP by SA1 - 2017
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> ABS Census 2016 - Dwelling count by SSC
        </Typography>
        <Typography variant="body1" paragraph>
          <Dot className={classes.dot} /> ABS correspondence (population-weighted) SA12016 to SSC2016
        </Typography>
    </div>
  );
}
