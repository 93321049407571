import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  progressContainer: {
    width: '75vw',
    height: '100vh',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center'
  },
  progress: {
    margin: '1rem',
  },
}));

export default function CircularDeterminate() {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const tick = () => {
    // reset when reaching 100%
    setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
  }

  useEffect(() => {
    const timer = setInterval(tick, 20);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.progressContainer}>
      <CircularProgress className={classes.progress} variant="determinate" value={progress} />
    </div>
  );
}