import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
  environment: {
    'margin-top': '1rem',
    'text-align': 'justify'
  },

});

export default function Environment(props) {
  const classes = useStyles();

  return (
    <div className={classes.environment}>
        <Typography variant="h5">
          Environment methodology
        </Typography>
        <Typography variant="body1" paragraph>
          An indicative index that considers the quality of the local natural environmental using four measures: air pollution, light pollution, urban heat, and vegetation coverage.
        </Typography>

        <Typography variant="h5">
          Air Pollution
        </Typography>
        <Typography variant="h6">
          Data source:
        </Typography>
        <Typography variant="body1" paragraph>
        <Link href="https://earthengine.google.com/" target="_blank" rel="noreferrer">
          Google Earth Engine, Sentinel-5P satellite data, 2020</Link>
        </Typography>
        <Typography variant="h6">
          Method:
        </Typography>
        <Typography variant="body1" paragraph>
          The measure of air pollution is a composite of Sulphur Dioxide (SO2) and Nitrogen Dioxide (NO2) levels. Both are predominantly man-made in origin, primarily produced by the burning of fossil fuels. They are common components of smog. In very high concentrations, they can cause lung disease, irritation of the eyes and skin, and acid rain. While Sydney does not experience hazardous levels, the measure is designed to be indicative of general air quality.
        </Typography>
        
        <Typography variant="body1" paragraph>
          1. Satellite measures are collected for a six month period, with the latest from 2020
        </Typography>
        <Typography variant="body1" paragraph>
          2. Values are overlayed and averaged out over that period
        </Typography>
        <Typography variant="body1" paragraph>
          3. For each suburb, the average reading value for the area is calculated
        </Typography>
        <Typography variant="body1" paragraph>
          4. These suburb values are then ranked against all other Sydney suburbs
        </Typography>

        <Typography variant="h5">
          Light Pollution
        </Typography>
        <Typography variant="h6">
          Data source:
        </Typography>
        <Typography variant="body1" paragraph>
        <Link href="https://www.ngdc.noaa.gov/eog/viirs/download_dnb_composites.html" target="_blank" rel="noreferrer">
          National Centers for Environmental Information 
        </Link>
        </Typography>
        <Typography variant="h6">
          Method:
        </Typography> 
        <Typography variant="body1" paragraph>
          To calculate light pollution, monthly radiance data for 2018 was collected (with the exception of June as data was unavailable). This data is provided to users after filtering to exclude 'stray light, lightening, lunar illumination and cloud cover'.
        </Typography>
        <Typography variant="body1" paragraph>
          1. Data is filtered to exclude negative data and all data with a value greater than 255 bits
        </Typography>
        <Typography variant="body1" paragraph>
          2. For each month, the satellite image is rastered with each suburb area.
        </Typography>
        <Typography variant="body1" paragraph>
          3. The mean band value is then calculated and given a score between zero and 10. The lower the mean band value, the higher the brightness, thus the lower the score.
        </Typography>
        <Typography variant="body1" paragraph>
          4. Equal weighting is given to each month.
        </Typography>
        <Typography variant="body1" paragraph>
          5. Scores are then summed up and rescaled to between zero and 10.
        </Typography>
        <Typography variant="body1" paragraph>
          Note: as some suburbs fluctuate considerably across the year, the band values are not averaged. The two sub-indices (air pollution and light pollution) are equally weighted, summed and then a minmax scale is applied to scale the score between zero and 10.
        </Typography>


        <Typography variant="h5">
          Urban Heat Islands
        </Typography>
        <Typography variant="h6">
          Data source:
        </Typography>
        <Typography variant="body1" paragraph>
          <Link href="https://citydata.be.unsw.edu.au/layers/geonode:UHI_MMB_SUA_20160" target="_blank" rel="noreferrer">
            City Futures Research Centre, University of New South Wales, 2019. 
          </Link>
        </Typography>
        <Typography variant="body1" paragraph>
          Used under  <Link href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noreferrer">
          Creative Commons license 4.0
          </Link>
        </Typography>
        <Typography variant="h6">
          Method:
        </Typography>
        <Typography variant="body1" paragraph>
          The Urban Heat Island (UHI) dataset measures the effects of urbanisation on land surface temperatures across Sydney Greater Metropolitan Area for the Summer of 2015-2016.        
        </Typography>
        <Typography variant="body1" paragraph>
          UHI shows the variation of temperature to a non-urban vegetated reference, such as heavily wooded areas or national parks around Sydney.
        </Typography>
        <Typography variant="body1" paragraph>
          Derived from the analysis of thermal and infrared data from the Landsat satellite, the dataset has been combined with the Australian Bureau of Statistics (ABS) Mesh Block polygon dataset to provide a mean UHI temperature that enables multi-scale spatial analysis of the relationship of heat to green cover. This was then rasterised by Geografia, and aggregated by suburb average.
        </Typography>

        <Typography variant="h5">
          Vegetation Cover
        </Typography>
        <Typography variant="h6">
          Data source:
        </Typography>
        <Typography variant="body1" paragraph>
          <Link href="https://citydata.be.unsw.edu.au/layers/geonode:UHI_MMB_SUA_20160" target="_blank" rel="noreferrer">
            City Futures Research Centre, University of New South Wales, 2019. 
          </Link>
        </Typography>
        <Typography variant="body1" paragraph>
          Used under  <Link href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noreferrer">
          Creative Commons license 4.0
          </Link>
        </Typography>
        <Typography variant="h6">
          Method:
        </Typography>
        <Typography variant="body1" paragraph>
          The NSW Urban Vegetation Cover to Modified Mesh Block 2016 provides both an area and percentage of vegetation for city blocks and infrastructure corridors in the Sydney Greater Metropolitan Area as of 2016. Devised by the University of New South Wales, the layer provides a % coverage of vegetation by area, across Sydney’s Greater Metropolitan Area. This was then rasterised by Geografia, and aggregated by suburb average.
        </Typography>
            
    </div>
  );
}
