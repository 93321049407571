import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { ApolloConsumer } from 'react-apollo';
import Button from '@material-ui/core/Button';
import {filterArrayOfObjectByKey, getSscCodeByName, getSuggestions} from '../utils/utils';
import renderInputComponent from './suggestion-input';
import renderSuggestion from './suggestion-item';

function getSuggestionValue(suggestion) {
  return suggestion.ssc_name16;
}

const useStyles = makeStyles({
    root: {
      'padding': '1rem',
      flexGrow: 1,
      'border-radius': '1rem',
      'margin-top': '2rem'
    },
    container: {
      position: 'relative',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: '1rem',
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    buttonContainer: {
        'margin-top': '3rem',
        'text-align': 'center'

    },
    button: {
        'margin-left': '1rem',
        'margin-bottom': '1rem'
    },
    iconButton: {
        padding: 10,
      },
});
  

export default function Suggestion({suggestionData}) {
    //console.log('suggestionData', suggestionData)
  const suburbList = filterArrayOfObjectByKey(suggestionData, 'ssc_name16')  
  const classes = useStyles();
  const [error, setError] = useState(null)
  const [state, setState] = useState('');

  const [stateSuggestions, setSuggestions] = useState([]);

  const handleSuggestionsFetchRequested = ({ value }) => {
      //console.log('handleSuggestionsFetchRequested', value)
    setSuggestions(getSuggestions(suggestionData, value));
    //setError('')
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
    setError('')
  };

  const handleChange = (event, { newValue }) => {
      //console.log('handleChange', name, event, newValue)
    setState(newValue);
    setError('')
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
    <ApolloConsumer>
    {client => (
        <div className={classes.root}>
            <Typography variant="h6">
                Search for a suburb within the selected region
            </Typography>
            <Typography variant="body2" color="error">
                {error}
            </Typography>
            <Autosuggest
                {...autosuggestProps}
                inputProps={{
                classes,
                id: 'autosuggest',
                label: 'Suburb',
                placeholder: 'Enter suburb',
                value: state,
                onChange: handleChange,
                type: 'search',
                }}

                renderSuggestionsContainer={options => (
                <Paper {...options.containerProps} square>
                    {options.children}
                </Paper>
                )}
            />
            <div className={classes.buttonContainer} >
                <Button variant="contained" color="primary" className={classes.button} data-cy="suggestion-clear"
                disabled={state.length > 0 ? false: true}
                onClick={(e) => {
                    e.preventDefault()
                    setError('')
                    setState('')            
                }}>Clear</Button>
                <Button variant="contained" color="primary"  className={classes.button} data-cy="suggestion-view"
                    onClick={(e) => {
                        e.persist()
                        if(suburbList.includes(state)) {
                            const selected_suburb = getSscCodeByName(suggestionData, state)
                            client.writeData({ data: {  fullDetail: false, 
                                selected_ssc_code16: selected_suburb.ssc_code16,
                                lat: selected_suburb.lat,
                                lon: selected_suburb.lon
                            } });
                        } else {
                            setError('Invalid Suburb')
                        }
                    }}>View</Button>
            </div>              
        </div>
    )}
    </ApolloConsumer>
  );
}
 