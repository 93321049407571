import React from 'react';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import SliderPool from '../components/slider-pool'
import ResultsContainer from  '../containers/results-container';
import Tables from '../components/tables';
import FilterContainer from '../containers/filters-container'
import { Query } from 'react-apollo';
import Header from '../components/header';
import WelcomeDialog from '../components/welcome-dialog';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  container: {
    width: '100%',
    height: '100%',

  },
  selection: {
    padding: '10px',
    'height': '100vh',
    'overflow-y': 'auto',
    'overflow-x': 'hidden',
    'position': 'relative'
  },
});
export const GET_DROPDOWN_VALUES = gql`
  query GetDropdownValues {
    table @client
    column @client
    value @client
    hasFilters @client
  }

`;
export default function Home() {
  const classes = useStyles();

  return (
    <Query query={GET_DROPDOWN_VALUES}>
    {({ data, loading, error }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>ERROR: {error.message}</p>;
      //console.log('Home data', data)
      return data && 
        <div className={classes.root}>
          <Grid className={classes.container} container spacing={0}>

            <Grid item xs={3} className={classes.selection}>
                <Header />
                <Tables />
                <SliderPool />
                <FilterContainer />

            </Grid>
            <Grid item xs={9}>
                <ResultsContainer table={data.table} column={data.column} value={data.value} hasFilters={data.hasFilters}/>
            </Grid>
          </Grid>
          <WelcomeDialog  />
        </div>
    }}
  </Query>
  );
};
