import React from 'react';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ApolloConsumer } from 'react-apollo';
import { Query } from 'react-apollo';
import Transport from './transport';
import Amenity from './amenity';
import Health from './health';
import Education from './education';
import Employment from './employment';
import Environment from './environment';
import TechnicalHeading from './technical-heading';
import Community from './community';
import Density from './density';
import TabContainer from '../tabContainer';
import {GET_DIALOG} from '../../queries/queries';

const useStyles = makeStyles({
  technical: {
    'text-align': 'justify'
  },
});

export default function Technical(props) {
  const classes = useStyles();

  return (
    <ApolloConsumer>
    {client =>  (
      <Query query={GET_DIALOG}>
      {({ data, loading, error }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error!</p>;
//console.log('technical', data)
        return data && 
        <div className={classes.techinical} data-cy="technical">
          <TechnicalHeading />
          <AppBar position="static" color="default">
            <Tabs value={data.dialogTabSection}
            onChange={(event, newValue) => {
              client.writeData({ 
                data: {
                  dialogTabSection: newValue,
                } 
              });

            }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth">
              <Tab label="Transport" />
              <Tab label="Amenity" />
              <Tab label="Health" />
              <Tab label="Education" />
              <Tab label="Employment" />
              <Tab label="Environment" />
              <Tab label="Community" />
              <Tab label="Density" />
            </Tabs>
          </AppBar>

          {data.dialogTabSection === 0 && <TabContainer><Transport /></TabContainer>}
          {data.dialogTabSection === 1 && <TabContainer><Amenity /></TabContainer>}
          {data.dialogTabSection === 2 && <TabContainer><Health /></TabContainer>}
          {data.dialogTabSection === 3 && <TabContainer><Education /></TabContainer>}
          {data.dialogTabSection === 4 && <TabContainer><Employment /></TabContainer>}
          {data.dialogTabSection === 5 && <TabContainer><Environment /></TabContainer>}
          {data.dialogTabSection === 6 && <TabContainer><Community /></TabContainer>}
          {data.dialogTabSection === 7 && <TabContainer><Density /></TabContainer>}
        </div>
      }}
      </Query>

    )}

    </ApolloConsumer>
  );
}
