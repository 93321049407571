import React from 'react';
import { makeStyles } from '@material-ui/styles';
import numeral from 'numeral';
import { Query } from 'react-apollo';
import { Loading } from '../components';
import {GET_FULL_DETAIL} from '../queries/queries';
import Typography from '@material-ui/core/Typography';
import {getScoreObject, mapColors, getScoreColor} from '../utils/utils';
const useStyles = makeStyles({
  root: {
    width: '100%',
    'text-align': 'center'
  },
  heading: {
    'font-weight': 600,
  },
  link: {
    '&:hover': {
      'font-weight': 600,
    }
  },
  seperator: {
    height: '0.5rem',
    'margin': '1rem -2rem',
  },
  details: {
    display: 'grid',
    'grid-template-columns': 'repeat( 6, minmax(10rem, 1fr) )',
    'justify-items': 'center',
    'margin': '0 7rem'
  },
  population: {
    'grid-column': '1/ span 2',
    'justify-self': 'left'
  },
  age: {
    'grid-column': '3/ span 2'
  },
  income: {
    'grid-column': '5/ span 2',
    'justify-self': 'right'
  },
  less15: {
    'grid-column': '1/ span 3'
  },
  more65: {
    'grid-column': '4/ span 3'
  }
});

export default function ResultSingleFullDetail({ decendingData}) {

  const classes = useStyles();
  return (
    <Query query={GET_FULL_DETAIL}>
      {({ data, loading, error }) => {
        if (loading) return <p><Loading/></p>;
        if (error) return <p>ERROR: {error.message}</p>;
        const selectedScore = getScoreObject(decendingData, parseInt(data.selected_ssc_code16))
        //console.log('data input', data, 'selectedScore', selectedScore) 
        const buckets = JSON.parse(localStorage.getItem('buckets'))

        const scoreColor = getScoreColor(buckets, selectedScore.value.render_score)
        const scoreStyle = {
          color: scoreColor
        }
        const backgroundStyle = {
          'backgroundColor': scoreColor
        }
        return (
          <div className={classes.root}>
            <Typography variant="h4" gutterBottom >
              Suburb Profile: {selectedScore.value.ssc_name16}
            </Typography>
            <div className={classes.seperator} style={backgroundStyle}></div>
            <Typography variant="h6" gutterBottom>
              SUITABILITY RATING: <span style={scoreStyle}> {mapColors[scoreColor].toUpperCase()} </span>
              | RANK: <span data-cy="rank" style={scoreStyle}>#{selectedScore.index + 1}</span>
            </Typography>
            <Typography variant="button" gutterBottom>
              
            </Typography>
            <div className={classes.details}>
              <Typography data-cy="population" variant="body2" gutterBottom className={classes.population}>
                Population: <strong>{numeral(selectedScore.value.population).format('0,0')}</strong>
              </Typography>
              <Typography data-cy="age" variant="body2" gutterBottom className={classes.age}>
                Median age: <strong>{selectedScore.value.median_age} years old</strong>
              </Typography>
              <Typography data-cy="income" variant="body2" gutterBottom className={classes.income}>
                Household income p/w: <strong>{numeral(selectedScore.value.hh_income).format('$0,0')}</strong>
              </Typography>
              <Typography data-cy="less15" variant="body2" gutterBottom className={classes.less15}>
                Proportion under 15 years old: <strong>{selectedScore.value.pc_under15}%</strong>
              </Typography>
              <Typography data-cy="more65" variant="body2" gutterBottom className={classes.more65}>
                Proportion 65 years and above: <strong>{selectedScore.value.pc_65plus}%</strong>
              </Typography>
            </div>
            <div className={classes.seperator} style={backgroundStyle}></div>
          </div>
          )
      }}
    </Query>
  );  
}
