import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import {property_values} from '../../context/mainContext';
import numeral from 'numeral';

const useStyles = makeStyles({
  formControl: {
    margin: '1rem',
    minWidth: '10rem',
  },
});

export const GET_VALUE = gql`
  query GetValue {
    column @client
    value @client
    totalScore @client
    disabled @client
    hasFilters  @client
  }
`;
export default function Value() {
  const classes = useStyles();
  const theRef = useRef()
  let displayPrice = {
    label: "Max Budget",
    width: 90
  }
  return (
      <Query query={GET_VALUE}>
        {({ data, client }) => {
          //console.log('Value data 111', data)
          if (!data.value && !data.column) {
            return <p data-testid="empty-message">No data</p>
          } else {
            const [tenure, property_type] = data.column.split('_')
            if (tenure === 'rent') {
              displayPrice = {
                label: "Max Budget (p/w)",
                width: 140
              }
            }
             
            const values = property_values[tenure][property_type]
            return (
              <FormControl variant="outlined" className={classes.formControl} disabled={!data.hasFilters || data.disabled}>
                <InputLabel ref={theRef} htmlFor="outlined-value" data-cy={`price-${tenure}`}>
                  {displayPrice.label}
                </InputLabel>
                <Select autoWidth={true}
                  value={data.value}
                  onChange={(e) => {
                    client.writeData({ data: { 
                      value: e.target.value, 
                      selected_ssc_code16: null,
                      fullDetail: false
                    } });
                  }}
                  input={
                    <OutlinedInput
                      labelWidth={displayPrice.width}
                      name="value"
                      id="outlined-value"
                    />
                  }>
                  {
                    values.map( (item, index) => {
                      let renderItem
                      if(item === 'Any') {
                        renderItem = item
                      } else {
                        renderItem = numeral(item).format('$0,0')
                      }
                      return <MenuItem key={index} value={item}>{renderItem}</MenuItem>
                    })
                  }
                </Select>
              </FormControl> 
            )
          }
          
        }}
      </Query>

  );

}
