import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/lab/Slider';
import numeral from 'numeral';
import Icon from '@material-ui/core/Icon';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowTooltip from './arrow-tooltips';
import ReactGA from 'react-ga';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { ApolloConsumer } from 'react-apollo';

const useStyles = makeStyles({

  'slider-container': {
    display: 'grid',
    'grid-template-columns': '2rem 1fr 4rem 1rem',
    'grid-row-gap': '1rem',
    margin: '1rem 1rem 2rem',
  },
  content: {
    'justify-self': 'start',
    'width': '16rem'
  },
  slider: {
    'grid-column': '1/-1',
  },
  icon: {
    'margin-left': '-1rem'
  },
  infoicon: {
    'fontSize': '2.3rem',
    'color': '#fff',
    '&:hover': {
      'cursor': 'pointer'
    }
  },
  clear: {
    'justify-self': 'end',
    color: '#ff0202',
    'font-size': '2.3rem',
    '&:hover': {
      'cursor': 'pointer'
    }
  },
});

const StepSlider = (props) => {
  const {labelId, index, label, value, onPropChange, icon, tooltips} = props
  const classes = useStyles();
  const handleChange = (event, stateValue) => {
    onPropChange(stateValue, labelId)
    ReactGA.event({
      category: 'Slider',
      action: `User is using slider`,
      label: labelId,
      value: stateValue
    });
  }

  const handleClearClick = () => {
    onPropChange(0, labelId)
  }
//console.log('labelId',labelId,'stateValue', value)
  return (
    <ApolloConsumer>
    {client => (
      <div className={classes['slider-container']}>
          <Typography className={`${classes.icon}`} variant="body1" >
            <Icon>{icon}</Icon>
          </Typography>
          <ArrowTooltip title={tooltips} 
              placement="right"
              data-cy={`slider-tooltip-${labelId}`}
              >
            <Typography className={`${classes.content}`} variant="body1" id={labelId}>{label}: {numeral(value).format('0')} pts</Typography>
          </ArrowTooltip>
          <InfoIcon className={classes.infoicon} data-cy={`info-icon-${labelId}`} 
          onClick={(e) => {
            let iconInfo  = {
              dialogOpen: true,
              dialogTab: 2, 
              dialogTabSection: index }
            client.writeData({ data: iconInfo })
            e.preventDefault()
          }}  />
          {value && <ClearIcon data-cy={`clear-${labelId}`} color="primary" className={classes.clear} onClick={handleClearClick}/>}
          <Slider
            className={classes.slider}
            value={value}
            min={0}
            max={100}
            step={1}
            aria-labelledby={labelId}
            onChange={handleChange}
          />
      </div>
    )}
    </ApolloConsumer>
  );
}
export default StepSlider