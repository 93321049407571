import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

const useStyles = makeStyles({
  formControl: {
    margin: '1rem 0',
    minWidth: '10rem',
  },
});

export const GET_COLUMN = gql`
  query GetColumn {
    column @client
    disabled @client
    hasFilters @client
  }
`;

export default function PropertyType() {
  const classes = useStyles();
  const theRef = useRef()
 
  return (
    <Query query={GET_COLUMN}>
      {({ data, client }) => {
        //console.log('Property type data', data)
        if (!data.column) {
          return <p data-testid="empty-message">No data</p>
        } else {
          const [tenure, property_type] = data.column.split('_')
          return (
            <FormControl variant="outlined" className={classes.formControl} disabled={!data.hasFilters || data.disabled}>
            <InputLabel ref={theRef} htmlFor="outlined-property-type">
              Dwelling type
            </InputLabel>
            <Select
              value={property_type}
              onChange={(e) => {
                client.writeData({ 
                  data: { 
                    column:`${tenure}_${e.target.value}`,
                    value: "Any",
                    selected_ssc_code16: null,
                    fullDetail: false
                    } 
                  });
                }}
              input={
                <OutlinedInput
                  labelWidth={100}
                  name="property-type"
                  id="outlined-property-type"
                />
              }
            >
              <MenuItem value="house">House</MenuItem>
              <MenuItem value="unit">Unit</MenuItem>
            </Select>
          </FormControl>
          )
        }
      }}
    </Query>

  );

}
