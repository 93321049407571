import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Value from '../components/filters/value'
import Tenure from '../components/filters/tenure'
import PropertyType from '../components/filters/property-type'
import Typography from '@material-ui/core/Typography';
import ArrowTooltip from '../components/arrow-tooltips';
import Fab from '../components/fab';
import Switches from '../components/switch-basic';

const useStyles = makeStyles({
  root: {
    'margin-bottom': '1rem',
    width: '100%'
  },
  heading: {
    'width': '40rem'
  },
  container: {
    display: 'grid',
    'grid-template-columns': '10rem 12rem 1fr'
  }
});


export default function FiltersContainer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.heading}>
      
        <ArrowTooltip title="Select your preferred housing type and price" 
        placement="right"
        data-cy="filter-housing-tooltip"
        >
          <Typography variant="h6" gutterBottom>
          <Fab label="Step 3" text="3" /> 
            <Switches />
            Filter housing preferences:
          
          </Typography>
        </ArrowTooltip>
      </div>


      <div className={classes.container}>
        <Tenure/>
        <PropertyType/>
        <Value/>
      </div>
    </div>
  );
}
