import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {Scatter} from 'react-chartjs-2';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {chartOptions, makeChartData, chartHeadings} from '../utils/chart';
import { getScoreObject } from '../utils/utils';
import Typography from '@material-ui/core/Typography';
import { ApolloConsumer } from 'react-apollo';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles({
  container: {
    '& canvas': { 
      'margin': '0 auto'
    }
  },
  heading: {
    'display': 'flex',
    'justify-content': 'center'
  },
  formControl: {
    margin: '1rem 0',
    minWidth: '10rem',

  },
  text:{
    margin: '1rem 0.5rem',
    'text-align': 'justify'
  },
  infoicon: {
    'fontSize': '3rem',
    'margin': '1rem',
    'color': '#fff',
    '&:hover': {
      'cursor': 'pointer'
    }
  },
});

export default function ScatterChart ({type, data, column, selected_ssc_code16}) {
  const [tenure, property_type] = column.split('_')
  const dropdowns = chartHeadings[type].dropdowns
  const [state, setState] = useState(Object.keys(dropdowns)[0])
  const selectedScore = getScoreObject(data, parseInt(selected_ssc_code16))
  const xLabel = `Median ${property_type} ${tenure} price`
  let yLabel = `${dropdowns[state]} ${chartHeadings[type].label}`
  const introText = chartHeadings[type].intro
  const showInfoIcon = chartHeadings[type].showInfoIcon
  //console.log('type', type, 'dropdowns', dropdowns, 'data', data, 'column', column, 'selected_ssc_code16',selected_ssc_code16, 'selectedScore', selectedScore)
  const datasetKeyProvider = () => {
      return Math.random(); 
  }
  const classes = useStyles();
    return (
      <ApolloConsumer>
      { client =>  (
      <div className={classes.container}>
        <Typography data-cy="intro" variant="body1" className={classes.text}>
          {ReactHtmlParser(introText)}
        </Typography>
        <div className={classes.heading}>
          {showInfoIcon && <InfoIcon className={classes.infoicon} data-cy={`info-icon-${type}`} 
            onClick={(e) => {
              let iconInfo  = {
                dialogOpen: true,
                dialogTab: 2, 
                dialogTabSection: 7 }
              client.writeData({ data: iconInfo })
              e.preventDefault()
            }}  /> }
          <FormControl className={classes.formControl}>
            <Select
              value={state}
              displayEmpty
              name="score"
              onChange={(e) => {
                //console.log('onchange', e)
                  setState(e.target.value)
              }}
            >
            {
              Object.keys(dropdowns).map( (key, index) => {
              return <MenuItem key={index} value={key}>{dropdowns[key]}</MenuItem>
              })
            }
            </Select>
          </FormControl>
          <Typography data-cy="chart-heading" variant="body1" className={classes.text}>
          by {xLabel}
          </Typography>
        </div>
          <Scatter
            data={makeChartData(data, column, state, selectedScore)} 
            options={chartOptions(xLabel, yLabel)}
            datasetKeyProvider={datasetKeyProvider} 
            width={740}
            height={300}
            getElementAtEvent= { element => {
              if(element.length > 0) {
                //console.log('element', element)
                const { datasets } = element[0]._chart.tooltip._data
                const datasetIndex = element[0]._datasetIndex
                const dataIndex = element[0]._index
                //console.log('datasets[datasetIndex]', datasets[datasetIndex], 'dataIndex', dataIndex, 'element', element)
                client.writeData({ 
                  data: {  
                    fullDetail: true, 
                    selected_ssc_code16: datasets[datasetIndex].data[dataIndex].ssc_code16,
                    lat: datasets[datasetIndex].data[dataIndex].lat,
                    lon: datasets[datasetIndex].data[dataIndex].lon
                } });
              }

            }}
          />
      </div>
      )}
      </ApolloConsumer>
    );

};

