import React, { Fragment } from 'react';
import styled from 'react-emotion';
export default function PageContainer(props) {
  return (
    <Fragment>
      <Container>{props.children}</Container>
    </Fragment>
  );
}

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const Container = styled('div')({
  width: '100%',
  height: '100%',

});
