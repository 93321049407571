import React from 'react';
import { makeStyles } from '@material-ui/styles';
import urban from '../assets/images/urban-mosaic.png';
import InfoIcon from '@material-ui/icons/Info';
import ArrowTooltip from './arrow-tooltips';
import { ApolloConsumer } from 'react-apollo';
import HeaderDialog from './header-dialog';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  header: {
    display: 'grid',
    'grid-template-columns': '15rem 1fr 4rem 11rem',
    'grid-gap': '1rem',
    'margin-bottom': '1rem'
  },
  header__link: {
    'text-decoration': 'none',
    'align-self': 'center',
  },
  header__image: {
    width: '15rem',
    'justify-self': 'start'
  },
  icon: {
    'fontSize': '4.5rem',
    'color': '#fff',
    'grid-column': '3/ span 1'
  },
  text: {
    'grid-column': '4/-1',
    'align-self': 'center',
    'line-height': '1.2',
    'font-weight': '600'
  }
});

export default function Header(props) {
  const classes = useStyles();

  return (
    <ApolloConsumer>
    { client =>  (
        <div className={classes.header}>
          <a href="/" className={classes.header__link}>
            <img src={urban} alt="urban mosaic" className={classes.header__image} />
          </a>
          <ArrowTooltip 
          title="Click for more information" 
          placement="right"
          data-cy="header-tooltip">
            <InfoIcon className={classes.icon} data-cy="infoicon" 
            onClick={(clickData) => {
              client.writeData({ 
                data: { 
                  dialogOpen: true,                    
                  dialogTab: 0,
                  dialogTabSection: 0
                } 
              });
            }}
            />
          </ArrowTooltip>
          <Typography variant="body1" className={classes.text}>
          How to use this website
        </Typography>
          <HeaderDialog />
        </div>
    )}
    </ApolloConsumer>
  );
}
//Click here for more information and instructions on how to use this tool