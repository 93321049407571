import gql from 'graphql-tag';

export const GET_SCORES = gql`
query scores($table: String!,$column: String!, $value: String!, $hasFilters:Boolean!) {
  transport @client
  amenity @client
  health @client
  education @client
  employment @client
  environment @client
  community @client
  totalScore @client
  selected_ssc_code16 @client
  lat @client
  lon @client
  fullDetail @client
  scores(table: $table,column: $column, value: $value, hasFilters: $hasFilters) {
    time
    total_rows
    scores {
      ssc_code16
      ssc_name16
      score_transport
      score_amenity
      score_health
      score_education
      score_employment
      score_environment
      score_community
      lat
      lon
      median_age
      pc_under15
      pc_65plus
      population
      hh_income
      dwellings_per_ha
      persons_per_ha
      buy_house
      buy_unit
      rent_house
      rent_unit
    }

  }
}
`;
export const GET_DIALOG = gql`
  query GetDialog {
    dialogOpen @client
    dialogTab @client
    dialogTabSection @client
  }

`;

export const GET_SSC_CODE16 = gql`
  query GetSscCode16 {
    selected_ssc_code16 @client
    lat @client
    lon @client
  }
`;

export const GET_FULL_DETAIL = gql`
  query fullDetail {
    selected_ssc_code16 @client
    fullDetail @client
  }
`;