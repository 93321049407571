import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
//import gql from "graphql-tag";
import { ApolloProvider } from 'react-apollo';
import Pages from './pages';
import {resolvers, typeDefs} from './resolvers';
import 'typeface-roboto';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'leaflet/dist/leaflet.css';
// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';
import CssBaseline from '@material-ui/core/CssBaseline';
import  injectStyles, {colors} from './styles';
import ReactGA from 'react-ga';

if (window.location.href.includes('udia.geografia.com.au')) {
  //PROD GA account
  ReactGA.initialize('UA-86661193-6');
} else {
  // TEST GA account
  ReactGA.initialize('UA-86661193-6');
}
ReactGA.pageview(window.location.pathname + window.location.search);
const App = () => {
  const theme = createMuiTheme({
    palette: {
      type: "dark",
      primary: {
        light: colors.primary,
        main: colors.secondary,
        dark: colors.primary,
        contrastText: colors.text,
      },
      secondary: {
        light: '#ff7961',
        main: '#d53e4f',  
        dark: '#ba000d',
        contrastText: '#000',
      },
      background: {
        default: colors.background
      },
      text: {
        primary: colors.text
      }
    },
    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
  
      ].join(','),
      htmlFontSize: 10,
      useNextVariants: true,
      h5: {
        'font-weight': '300',
        'border-bottom': '1px solid #fff',
        'margin-bottom': '2rem',
        'padding-bottom': '0.5rem'
      },

    },
    // overrides: {
    //   MuiSelect: {
    //       select: {
    //         background: "white",
    //           // "&:focus": {
    //           //     background: "white"
    //           // },
    //           color:'red'
    //       }
    //   },
    // }
  });
  const cache = new InMemoryCache();
  const link = new HttpLink({
    //uri: 'http://localhost:4000/graphql'
    uri: 'https://udia-graphql.herokuapp.com/'
  })
  const client = new ApolloClient({
    cache,
    link,
    resolvers,
    typeDefs
  })
  cache.writeData({
    data: {
      table: "scores_all_suburbs",
      column: "buy_house",
      value: "Any",
      transport: 14,
      amenity: 14,
      health: 14,
      education: 14,
      employment: 14,
      environment: 15,
      community: 15,
      totalScore: 0,
      render_score: 0,
      selected_ssc_code16: null,
      lat: null,
      lon: null,
      disabled: false,
      mapType: "dark",
      mapLGA: false,
      mapOpacity: 100,
      dialogOpen: false,
      dialogTab: 0,
      dialogTabSection: 0,
      fullDetail: false,
      hasFilters: false
    },
  });

  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Pages />
      </MuiThemeProvider>
    </ApolloProvider>
  )
}
injectStyles();
ReactDOM.render( <App />, document.getElementById('app'));