import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Query } from 'react-apollo';
import { Loading } from '.';
import { ApolloConsumer } from 'react-apollo';
import {GET_FULL_DETAIL} from '../queries/queries';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({

  buttons: {
    'display': 'grid',
    'justify-items': 'center',
    'grid-template-columns': '1fr 1fr',
    'margin-top': '2rem'
  }
});

export default function ResultSingleFullDetail({ decendingData}) {
  const classes = useStyles();
  return (
    <ApolloConsumer>
        {client => (
          <Query query={GET_FULL_DETAIL}>
            {({ data, loading, error }) => {
              if (loading) return <p><Loading/></p>;
              if (error) return <p>ERROR: {error.message}</p>;
              return (
                  <div className={classes.buttons}>
                    <Button data-cy="close-profile" variant="contained"  color="primary"
                    onClick={(e) => {
                      client.writeData({ data: {  fullDetail: false} });
                    }}
                    >Close full profile</Button>
                    <Button data-cy="result-list" variant="contained"  color="primary"
                    onClick={(e) => {
                      client.writeData({ data: {  fullDetail: false, selected_ssc_code16: null} });
                    }}
                    >Back to suburb list</Button>
                  </div>
                )
            }}
          </Query>
        )}
        </ApolloConsumer>
);  
}
